import React, { useContext, useState } from "react";
import { Modal } from "antd";
import { GlobalContext } from "../../Context/context";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GroupDeleteModal = ({
  data,
  isShowDeleteGroupModal,
  setIsShowDeleteGroupModalHandler,
  isRefreshMyGroupData,
  closeGroupDetailModal,
  // getMyGroupData
}) => {
  let { state, dispatch } = useContext(GlobalContext);
  const { group } = data ? data : {};
  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));

  console.log("group =>>> ", group);

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });

  const deleteGroupHandler = async () => {
    console.log("deleteGroupHandler");
    try {
      const response = await axios.post(
        `${state.baseUrl}group/${group?.id}/delete`,
        {},
        { headers: headers }
      );
      console.log("Delete Group response :>> ", response?.data);
      if (response?.data?.status === 200) {
        setIsShowDeleteGroupModalHandler(false);
        // Toast.fire({
        //   icon: "success",
        //   title: response?.data?.message
        //     ? response?.data?.message
        //     : "The group has been deleted successfully.",
        // });
      
        toast.success(
          response?.data?.message
            ? response?.data?.message+"..."
            : "The group has been deleted successfully..."
        );
       
        // closeGroupDetailModal(false);
        console.log('Deleted group data');
        // getMyGroupData()
        isRefreshMyGroupData(false);
        window.location.reload();
      }
    } catch (error) {
      console.log("Delete Group Error :>> ", error);
      setIsShowDeleteGroupModalHandler(false);
      //   Toast.fire({
      //     icon: "error",
      //     title: error?.response?.data?.message
      //       ? error?.response?.data?.message
      //       : "",
      //   });
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : ""
      );
    }
  };

  return (
    <Modal
      open={isShowDeleteGroupModal}
      onCancel={() => setIsShowDeleteGroupModalHandler(false)}
      maskClosable={false}
      closable={true}
      footer={false}
      // centered={true}
    >
      <div style={{ backgroundColor: "#ffffff", marginTop: 20 }}>
        <p
          style={{
            fontSize: 25,
            color: "#3b4a54",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
        >
          Delete {group?.name ? group.name : ""} group!
        </p>
        <p
          style={{
            fontSize: 18,
            color: "#667781",
            fontFamily: "Nunito",
            fontWeight: "500",
            marginTop: 10,
          }}
        >
          Are you sure you want to delete this group?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 20,
            marginTop: 60,
          }}
        >
          <button
            className="btn"
            onClick={() => setIsShowDeleteGroupModalHandler(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={deleteGroupHandler}>
            Delete Group
          </button>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
};

export default GroupDeleteModal;
