// import { createContext } from 'react';

// const UserContext = createContext();

// export default UserContext;

import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const GlobalContext = createContext("Initial Value");

let data = {
  darkTheme: true,
  user: {},
  // isLogin: null,
  isLogin: false,
  baseUrl: `https://e-ballot.ca/backend/api/v1/`, //// admin panel url for api's
  // localURI: window.location.href.includes("localhost")
  //   ? `localhost:3000`
  //   : `https://investor-web-app.web.app/`, // live site url
};

export default function ContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
}
