import React from "react";
import { Modal } from "antd";
import UserImage from "../../assets/userImage.jpeg";

const PollVoterList = ({ isShowVoterList, onClose, data }) => {
  const { name, voter_name, votes } = data ? data : {};

  console.log("votes ==>> ", votes);
  return (
    <Modal
      // title="New Poll Create"
      open={isShowVoterList}
      // onOk={createPollHandler}
      // okText="Create Poll"
      // closable={false}
      // okButtonProps={{ style: { fontSize: 15, fontFamily: "Nunito" } }}
      onCancel={() => onClose(false)}
      maskClosable={false}
      closeIcon={true}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 20,
        }}
      >
        <p
          style={{
            fontSize: 25,
            fontFamily: "Nunito",
            fontWeight: "600",
            color: "blue",
          }}
        >
          Voters Information
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
          marginBottom: 20,
          backgroundColor: "#8be5ee",
          padding: 15,
          borderRadius: 8,
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontFamily: "Nunito",
            fontWeight: "500",
            margin: 0,
            padding: 0,
          }}
        >
          {name ? name : ""}
        </p>
        <p
          style={{
            fontSize: 20,
            fontFamily: "Nunito",
            fontWeight: "500",
            margin: 0,
            padding: 0,
          }}
        >
          {votes ? votes : ""}
          {votes == 1 ? " Vote" : " Votes"}
        </p>
      </div>
      {voter_name?.map((item, index) => {
        // console.log('item :>> ', item);
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 20,
              marginBottom: 20,
              backgroundColor: "#daf6f8",
              padding: 10,
              borderRadius: 6,
            }}
          >
            <div
              style={{
                width: 40,
                height: 40,
                overflow: "hidden",
                borderRadius: "50%",
              }}
            >
              <img
                src={
                  item?.avatar === "" || null || undefined
                    ? UserImage
                    : item?.avatar
                }
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div>
              <p
                style={{
                  fontSize: 20,
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  margin: 0,
                  padding: 0,
                }}
              >
                {item?.name ? item?.name : ""}
              </p>
            </div>
          </div>
        );
      })}
    </Modal>
  );
};

export default PollVoterList;
