import React, { useState, useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

import axios from "axios";
// import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import "./Login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import InfoIcon from "@mui/icons-material/Info";
import { Formik } from "formik";
import image from "../../../assets/loginpage.png";
import loginImage from "../../../assets/login-image.png";
import topShape from "../../../assets/top-corner-shape.png";
import EmailIcon from "@mui/icons-material/Email";
import { InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import UserContext from "../../Context/context";
// import { baseURl } from "../../constants";

import { GlobalContext } from "../../../Context/context.js";
import Loader from "../../../assets/loader.gif";
// import React from "react";
// import Swal from "sweetalert2";
// import Swal from "sweetalert2";
// import "sweetalert2/src/sweetalert2.scss";

// CommonJS
const LoginPage = () => {
  // const user = useContext(UserContext);

  const navigate = useNavigate();
  let { state, dispatch } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  // const [user_Token, setUserToken] = useState(localStorage.getItem("token"));

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [isLoading, setIsloading] = useState(false);
  const token = localStorage.getItem("token");
  // useEffect(() => {

  //   if (token) {
  //     navigate("/")
  //   }
  // }, [])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  //Login Function
  const handleSubmit = async (event) => {
    toast.dismiss();
    event.preventDefault();
    const validationErrors = {};
    setIsloading(true);
    if (!email) {
      validationErrors.email = "Email is required";
      setIsloading(false);
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Invalid email format";
      setIsloading(false);
    }

    if (!password) {
      validationErrors.password = "Password is required";
      setIsloading(false);
    } else if (password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
      setIsloading(false);
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsloading(false);
    } else {
      setErrors({});
      const data = {
        email: email,
        password: password,
      };
      console.log("Login ====>>>>>> ", state?.baseUrl);
      try {
        const login_URL = `${state?.baseUrl}login`;
        const response = await axios.post(login_URL, data);
        console.log("Login response", response?.data);
        if (response.data.status === 200) {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(response?.data?.user?.id);
          }

          toast.success(
            response?.data?.message
              ? response?.data?.message
              : "Logged in successfully."
          );
          dispatch({
            type: "USER_LOGIN",
            payload: response?.data?.user,
          });

          localStorage.setItem("token", response?.data?.user?.token);
          localStorage.setItem("userName", response?.data?.user?.name);
          localStorage.setItem("userId", response?.data?.user?.id);

          // setTimeout(() => {
          //   navigate("/");
          // }, 2000);

          navigate("/");
          setIsloading(false);
        }
      } catch (error) {
        console.log("Login error", error);
        if (error && error?.response?.data?.status == 401) {
          // Toast.fire({
          //   icon: "error",
          //   title: error?.response?.data?.message
          //     ? error?.response?.data?.message
          //     : "User not found.",
          // });
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "User not found"
          );
        }
        dispatch({
          type: "USER_LOGOUT",
        });
        setIsloading(false);
      }
    }
  };

  return (
    <>
      <div className="main_container">
        {/*  ============ Left column ============ */}
        <div className="column">
          <div className="top-left-corner">
            <img src={topShape} className="top-corner-shape" />
          </div>
          <img src={loginImage} className="login-image" />
        </div>

        {/*  ============ Right column ============ */}
        <div className="column">
          <div className="login-form-container">
            <p className="login-heading">Login</p>

            {/* <p style={{ color: "#000000", fontSize: 15 }}>
              screen width: {screenWidth}
            </p>
            <p style={{ color: "#000000", fontSize: 15 }}>
              screen height: {screenHeight}
            </p> */}

            <form id="loginForm" onSubmit={handleSubmit}>
              <TextField
                className="formfields"
                // label="Email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginTop: 30 }}></div>

              <TextField
                className="formfields"
                // label="Password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={!!errors.password}
                helperText={errors.password}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </form>

            <div className="forgot-pass-container">
              <Link className="forget-password-lnk" to="/forget-password">
                Forget Password?
              </Link>
            </div>

            <div className="login-btn-container">
              <button
                className="login-btn"
                form="loginForm"
                disabled={isLoading}
              >
                Login
              </button>
            </div>

            {/* <div className="signup-lnk-container">
              <p className="signup-txt">Don't Have An Account?</p>
              <Link className="signup-link_text" to="/Signup">
                Signup
              </Link>
            </div> */}

            <div className="signup-lnk-container">
              <div style={{ margin: 0, padding: 0 }}>
                <p className="signup-txt">Don't Have An Account?</p>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link className="signup-link_text" to="/Signup">
                  Signup
                </Link>
              </div>
            </div>
          </div>

          <div className="shape-container"></div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default LoginPage;
