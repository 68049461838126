import React, { useContext, useState } from "react";
import { Modal } from "antd";
import axios from "axios";
import { GlobalContext } from "../Context/context";
import Swal from "sweetalert2";

const EditUserByAdminModal = ({
  setIsShowEditUserModalHandler,
  isShowEditUserModal,
  data,
  isRefreshGroupDetailData,
  group_uuid,
  onAssignAdminClick,
}) => {
  let { state, dispatch } = useContext(GlobalContext);
  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const { pivot, name } = data ? data : {};

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });

  return (
    <Modal
      open={isShowEditUserModal}
      onCancel={() => setIsShowEditUserModalHandler(false)}
      maskClosable={false}
      closable={true}
      footer={false}
      // centered={true}
    >
      <div style={{ backgroundColor: "#ffffff", marginTop: 20 }}>
        <p
          style={{
            fontSize: 25,
            color: "#3b4a54",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
        >
          Edit follower {name ? name : ""}
        </p>
        {/* <p
          style={{
            fontSize: 18,
            color: "#667781",
            fontFamily: "Nunito",
            fontWeight: "500",
            marginTop: 10,
          }}
        >
          Are you sure you want to remove this user?
        </p> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 20,
            marginTop: 60,
          }}
        >
          <button
            className="btn"
            onClick={() => setIsShowEditUserModalHandler(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onAssignAdminClick}>
            Assign User as Admin
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserByAdminModal;
