import React, { useState, useContext, useRef } from "react";
import { Button, Modal, Input } from "antd";
import axios from "axios";
import { GlobalContext } from "../Context/context";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./InviteUserForGroup.css";

const InviteUserForGroup = ({
  isShowAddUserModal,
  addUserModalHandler,
  groupData,
}) => {
  let { state, dispatch } = useContext(GlobalContext);

  const inputRefs = useRef([]);

  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [optionsArray, setOptionsArray] = useState(["User Email"]);
  const [inviteUser, setInviteUsers] = useState([""]);
  const [emailErrors, setEmailErrors] = useState(
    Array(inviteUser.length).fill(null)
  );

  console.log("emailErrors ===>> ", emailErrors);

  // console.log("inviteUser ===>>>>>>>>>>>>>>>> ", inviteUser);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const sharedProps = {
    ref: inputRefs,
  };

  const addOptionsFieldHandler = () => {
    const newOptionsArray = [...inviteUser, ``];
    setInviteUsers(newOptionsArray);
  };

  const deleteOptionsFieldHandler = (item) => {
    const deleteOptionsArray = inviteUser.filter((ele, ind) => ind !== item);
    return setInviteUsers(deleteOptionsArray);
  };

  const onChangeHandler = (value, index) => {
    // console.log('v', v);
    // let email = e.target.value
    // setInviteUsers([...inviteUser, email])

    setInviteUsers((prevValues) => {
      const newInputValues = [...prevValues];
      newInputValues[index] = value;
      return newInputValues;
    });
  };

  const addUserHandler = async () => {
    console.log("invite user working...", inviteUser);

    let allEmailsValid = false;

    const addUser_URL = `${state?.baseUrl}assing/user/group/${groupData?.id}`;

    const inviteUserCall = async () => {
      try {
        const response = await axios.post(
          addUser_URL,
          { email: inviteUser },
          { headers: headers }
        );
        console.log("Invite User response", response?.data);
        if (response?.data?.status === 200) {
          if (response?.data?.NotInvited.length > 0) {
            toast.error(
              "User already member in this group. Error sending invite."
            );
          } else {
            toast.success(
              response?.data?.message
                ? response?.data?.message
                : "User invitation mail sent successfully."
            );
          }
          // Toast.fire({
          //   icon: "success",
          //   title: response?.data?.message
          //     ? response?.data?.message
          //     : "User invitation mail sent successfully.",
          // });

          setInviteUsers(["User Email"]);
          addUserModalHandler(false);
        }
      } catch (error) {
        console.log("Invite User Error", error);
        addUserModalHandler(false);
        if (error?.response?.status === 403) {
          toast.error(
            error?.response?.data?.message ? error?.response?.data?.message : ""
            // : "Error sending invite."
          );
        }

        // Toast.fire({
        //   icon: "success",
        //   title: error?.response?.data?.message?.email[0],
        // });
      }
    };

    // Update the emailErrors array with error messages
    const newErrors = [...emailErrors];

    // for (let index = 0; index < inviteUser.length; index++) {
    //   const item = inviteUser[index];

    //   if (!/\S+@\S+\.\S+/.test(item)) {
    //     newErrors[index] = "Email is invalid";
    //     console.log("Email is invalid");
    //     addUserModalHandler(true);
    //     setEmailErrors(newErrors);
    //     return;
    //   } else {
    //     newErrors[index] = null;
    //   }
    // }

    // inviteUser.forEach((item, index) => {
    //   console.log("inviteUser item ==>> ", item);
    //   if (item === "") {
    //     newErrors[index] = "Email is required";
    //     console.log("Email is required");
    //     setEmailErrors(newErrors);
    //     allEmailsValid = false;
    //     return addUserModalHandler(true);
    //   } else if (!/\S+@\S+\.\S+/.test(item)) {
    //     newErrors[index] = "Email is invalid";
    //     console.log("Email is invalid");
    //     setEmailErrors(newErrors);
    //     allEmailsValid = false;
    //     return addUserModalHandler(true);
    //   } else {
    //     newErrors[index] = null;
    //     setEmailErrors(newErrors);

    //     allEmailsValid = true;
    //     console.log("Email is not empty and valid.. else called...");
    //     // inviteUserCall();
    //   }
    // });

    inviteUser.forEach((item, index) => {
      console.log("inviteUser item ==>> ", item);
      if (item === "") {
        newErrors[index] = "Email is required";
        console.log("Email is required");
        allEmailsValid = false;
      } else if (!/\S+@\S+\.\S+/.test(item)) {
        newErrors[index] = "Email is invalid";
        console.log("Email is invalid");
        allEmailsValid = false;
      } else {
        newErrors[index] = null;
        allEmailsValid = true;
        console.log("Email is not empty and valid.. else called...");
      }
    });

    setEmailErrors(newErrors);
    addUserModalHandler(!allEmailsValid);

    function checkUniqueEmails(array) {
      let uniqueSet = new Set(array);

      return uniqueSet.size === array.length;
    }

    if (!checkUniqueEmails(inviteUser)) {
      allEmailsValid = false;
      return toast.error("Emails must be unique");
    } else {
      // allEmailsValid = true;
      // inviteUserCall();
    }

    console.log("allEmailsValid ==>> ", allEmailsValid);

    if (allEmailsValid) {
      inviteUserCall();
    }

    // inviteUser.forEach((item, index) => {
    //   if (!/\S+@\S+\.\S+/.test(item)) {
    //     newErrors[index] = "Email is invalid";
    //     console.log("Email is invalid");
    //     addUserModalHandler(true);
    //     setEmailErrors(newErrors);
    //     return;
    //   } else {
    //     newErrors[index] = null; // No error
    //   }
    // });
  };

  return (
    <Modal
      title="Add User"
      open={isShowAddUserModal}
      onOk={addUserHandler}
      onCancel={() => addUserModalHandler(false)}
      okText="Invite"
      closeIcon={true}
      maskClosable={false}
      footer={null}
    >
      <div>
        {/* <Input
                    className="questionFiels"
                    showCount
                    // maxLength={100}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder='Enter Poll'
                /> */}
        {inviteUser.map((item, index) => {
          inputRefs.current[index] = React.createRef();
          return (
            <div key={index}>
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 15,
                  marginTop: 10,
                }}
              >
                <div style={{ width: "100%" }}>
                  <Input
                    // showCount maxLength={20}
                    ref={inputRefs.current[index]}
                    autoFocus
                    // {...sharedProps}
                    onChange={(e) => {
                      onChangeHandler(e.target.value, index);
                    }}
                    placeholder={"Enter User Email"}
                    className="jfvhejkd"
                  />
                  {/* <input
                    ref={inputRefs.current[index]}
                    style={{ padding: 10 }}
                    placeholder="Enter User Email"
                  /> */}
                </div>

                {/* {index === inviteUser?.length - 1 && (
                                <Button type="primary"
                                    onClick={addOptionsFieldHandler}
                                >
                                    Add
                                </Button>
                            )} */}
                {index !== inviteUser?.length - 1 && (
                  // <Button type="primary"
                  //     onClick={() => deleteOptionsFieldHandler(index)}
                  // >
                  //     Delete
                  // </Button>
                  <button
                    className=" btn btn-primary"
                    onClick={() => deleteOptionsFieldHandler(index)}
                    style={{ fontFamily: "Nunito" }}
                  >
                    Delete
                  </button>
                )}
              </div>
              {emailErrors[index] && (
                <div style={{ marginTop: 5, marginLeft: 2 }}>
                  <p style={{ color: "red" }}>{emailErrors[index]}</p>
                </div>
              )}
              {index === inviteUser?.length - 1 && (
                // <Button type="primary"
                //     onClick={addOptionsFieldHandler}
                // >
                //     Add
                // </Button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <button
                    className=" btn btn-primary mt-3"
                    onClick={() => {
                      addOptionsFieldHandler();
                      // Focus on the next input when Add Option is clicked
                      if (inputRefs.current[index + 1]) {
                        inputRefs.current[index + 1].current.focus();
                      }
                    }}
                    style={{ fontFamily: "Nunito" }}
                  >
                    Add Email
                  </button>
                </div>
              )}
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
            marginTop: 30,
            marginBottom: 0,
          }}
        >
          <button
            className=" btn btn-primary "
            style={{ fontFamily: "Nunito" }}
            onClick={() => addUserModalHandler(false)}
          >
            Cancel
          </button>
          <button
            className=" btn btn-primary "
            style={{ fontFamily: "Nunito" }}
            onClick={addUserHandler}
          >
            Invite
          </button>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
};

export default InviteUserForGroup;
