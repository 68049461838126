import React, { useState } from "react";
import UserImage from "../../assets/userImage.jpeg";
import "../chat.css";
import PersonIcon from "@mui/icons-material/Person";
import { Modal } from "antd";
import { GlobalContext } from "../../Context/context";
import axios from "axios";
import Swal from "sweetalert2";

const UserListGroupDetail = ({ item, isAdmin }) => {
  console.log("UserListGroupDetail ==>> ", item);

  const [isShowRemoveUserModal, setIsShowRemoveUserModal] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 7,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 8,
        // cursor: "pointer",
        boxShadow: "1px 2px 9px grey",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div className="userImageContainer">
          <img
            // src={
            //   item?.avatar === ("null" || "" || null || undefined)
            //     ? UserImage
            //     : item?.avatar
            // }
            src={item?.avatar ? item?.avatar : UserImage}
            alt=""
            className="userImage"
          />
        </div>
        <div>
          <p
            style={{
              fontSize: 20,
              fontFamily: "Nunito",
              fontWeight: "600",
              marginBottom: "0rem",
            }}
          >
            {isAdmin ? item?.name : item?.name ? item?.name : ""}
          </p>
        </div>
      </div>

      {isAdmin && (
        <div>
          <p
            style={{
              fontSize: 18,
              fontFamily: "Nunito",
              fontWeight: "600",
              color: "blue",
              marginBottom: "0rem",
            }}
          >
            Admin{" "}
          </p>
        </div>
      )}
    </div>
  );
};

export default UserListGroupDetail;

{
  /* {isShowRemoveUserModal && (
                <Modal
                    open={isShowRemoveUserModal}
                    onCancel={() => setIsShowRemoveUserModal(false)}
                    maskClosable={false}
                    closable={true}
                    footer={false}
                // centered={true}
                >
                    <div style={{ backgroundColor: "#ffffff", marginTop: 20 }}>
                        <p
                            style={{ fontSize: 25, color: "#3b4a54", fontFamily: "Nunito", fontWeight: "600" }}
                        >
                            Remove follower {`"Auto Mobile"`}!
                        </p>
                        <p
                            style={{ fontSize: 18, color: "#667781", fontFamily: "Nunito", fontWeight: "500", marginTop: 10 }}
                        >
                            Are you sure you want to remove this user?
                        </p>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 20, marginTop: 60 }}>
                            <button
                                className='btn'
                                onClick={() => setIsShowRemoveUserModal(false)}
                            >Cancel</button>
                            <button
                                className='btn btn-primary'
                                onClick={removeUserFromGroupByAdmin}
                            >Remove User</button>
                        </div>
                    </div>
                </Modal>
            )} */
}
