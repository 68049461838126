import React, { useState, useContext, useRef } from "react";
import { Modal, Input, DatePicker, TimePicker } from "antd";
import AddPollIcon from "@mui/icons-material/Add";
import className from "./PollComponent.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import axios from "axios";
import { GlobalContext } from "../../Context/context";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreatePoll.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxLength: 20,
    },
  },
};

const names = [
  "2 Minutes",
  "5 Minutes",
  "30 Minutes",
  "1 Hour",
  "3 Hours",
  "6 Hours",
  "12 Hours",
  "18 Hours",
  "24 Hours",
  "Custom",
];

const CreatePoll = ({
  isModalOpen,
  setIsModalOpen,
  groupID,
  refresh,
  refreshid,
}) => {
  let { state, dispatch } = useContext(GlobalContext);

  const inputRefs = useRef([]);

  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [optionsArray, setOptionsArray] = useState([""]);
  const [question, setQuestion] = useState("");
  const [selectTime, setSelectTime] = React.useState(["30 Minutes"]);
  const [errors, setErrors] = useState({});
  const [userDate, setuserDate] = useState("");
  const [userTime, setuserTime] = useState("");
  const [selectedValue, setSelectedValue] = useState("public");
  const [isCreatePollPressed, setIsCreatePollPressed] = useState(false);
  const [localTime,setLocalTIme] = useState(new Date())
  console.log("optionsArray :>> ", optionsArray);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const currentTimeFormatter = () => {
    const currentDate = new Date();
    const momentDate = moment(currentDate);
    const convertedDate = momentDate.format("YYYY-MM-DD HH:mm");
    return convertedDate;
  };

  const getFutureDateTime = (minutesToAdd) => {
    let endTime;
    if (minutesToAdd === "30 Minutes") {
      return (endTime = parseInt(minutesToAdd.split(" ")[0]));
    } else if (minutesToAdd === "2 Minutes") {
      return (endTime = parseInt(minutesToAdd.split(" ")[0]));
    } else if (minutesToAdd === "5 Minutes") {
      return (endTime = parseInt(minutesToAdd.split(" ")[0]));
    } else if (minutesToAdd === "Custom") {
      const dateTimeString = `${userDate} ${userTime}`;
      let startTime = new Date();
      let endTime = new Date(dateTimeString);
      const diff = endTime - startTime;
      let convertTime = Math.round(diff / 1000 / 60);
      return convertTime;
    } else {
      return (endTime = parseInt(minutesToAdd.split(" ")[0] * 60));
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectTime(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const addOptionsFieldHandler = () => {
    // const newOptionsArray = [...optionsArray, `Option ${optionsArray?.length + 1}`];
    const newOptionsArray = [...optionsArray, ``];
    setOptionsArray(newOptionsArray);
  };

  const deleteOptionsFieldHandler = (item, index) => {
    const deleteOptionsArray = optionsArray.filter((ele, ind) => ind !== item);
    return setOptionsArray(deleteOptionsArray);
  };

  const onChangeHandler = (value, index) => {
    // if(value == ''){
    //   return
    // }
    console.log('Pooll +++>> ',index,value);
    setOptionsArray((prev) => {
      let newInputValues = [...prev];
      newInputValues[index] = value;
      return newInputValues;
    });
  };
  const onChange = (date, dateString) => {
    let userDate = new Date(date);
    console.log('Match dates => ',userDate.toLocaleDateString());
    
    console.log('current date =>',new Date().toLocaleDateString());
    setuserDate(dateString);
  };
  const onChangetime = (time, timeString) => {
    setLocalTIme(time)
    setuserTime(timeString);
  };

  let isOptionsValid = false;

  const checkAllOptions = (array) => {
    // for (let i = 1; i < array.length; i++) {
    //   if (array[i] === array[0]) {
    //     isOptionsValid = true;
    //     return false;
    //   } else {
    //     isOptionsValid = false;
    //     return true;
    //   }
    // }
    // isOptionsValid = false;
    // return true;

    let uniqueSet = new Set(array);

    return uniqueSet.size === array.length;
  };

  console.log(
    "checkAllOptions(optionsArray) :>> ",
    checkAllOptions(optionsArray)
  );

  const createPollHandler = async () => {
    toast.dismiss()
    setIsCreatePollPressed(true);
    const validationErrors = {};
    if (!question) {
      validationErrors.question = "Name is required";
      isOptionsValid = false;
    } else if (question?.length < 5) {
      validationErrors.question = "Name must be at least 5 characters long.";
      isOptionsValid = false;
    }

    if (optionsArray.length < 2) {
      validationErrors.option = "Should have at least two options";
      isOptionsValid = false;
    }

    if (checkAllOptions(optionsArray)) {
      isOptionsValid = true;
    } else {
      validationErrors.optionsCheck = "Enter unique value in all options.";
      isOptionsValid = false;
    }
    let anyEmpty = false;
    optionsArray.map((val,index)=>{
      if(val == ''){
        toast.error(
            "The poll must have value."
        );
        anyEmpty = true;
      }
    })
    if(anyEmpty){
      setIsCreatePollPressed(false)
      return
    }
    // if (getFutureDateTime(selectTime[0]) < currentTimeFormatter()) {
    //   validationErrors.time =
    //     "The provided time is earlier than the current time.";
    //   isOptionsValid = false;
    // }

    if (selectTime[0] === "Custom") {
      console.log("selected time is Custom");

      if (!userDate || !userTime) {
        console.log('Error no time/date');
        validationErrors.time = "Date/Time is required.";
        setErrors(validationErrors)
        isOptionsValid = false;
        setIsCreatePollPressed(false)
        return
      }else{

      if(new Date(userDate).toLocaleDateString() < new Date().toLocaleDateString()){
        console.log('date is in past');
        validationErrors.time = "Date must be greater than or equal to current date.";
        setIsCreatePollPressed(false)
        setErrors(validationErrors)
        return
      }
      if(new Date(localTime).toLocaleTimeString() < new Date().toLocaleTimeString()){
        console.log('date is in past');
        validationErrors.time = "Time must be greater than current time.";
        setIsCreatePollPressed(false)
        setErrors(validationErrors)
        return
      }
    }

    } else {
        
      console.log("selected time is not Custom");
    }
   
    

    // if ((selectTime[0] === "Custom" && userDate === "") || userTime === "") {
    //   validationErrors.time = "Date/Time is required.";
    //   isOptionsValid = false;
    // } else {
    //   isOptionsValid = true;
    // }

    console.log("isOptionsValid ? ", isOptionsValid);

    if (isOptionsValid && Object.keys(validationErrors).length === 0) {
      setErrors({});

      const filteredOptionsArray = optionsArray.filter((item) => item !== "");

      const data = {
        title: question,
        end_time: getFutureDateTime(selectTime[0]),
        option: filteredOptionsArray,
        poll_privacy: selectedValue,
      };

      console.log("payload create poll ==>> ", data);
      console.log("payload group id ==>> ", groupID);

      try {
        const response = await axios.post(
          `${state?.baseUrl}polls/${groupID}/store`,
          data,
          { headers: headers }
        );
        console.log("Create Poll response", response?.data);
        if (response?.data?.status === 201) {
          // Toast.fire({
          //   icon: "success",
          //   title: response?.data?.message
          //     ? response?.data?.message
          //     : "Poll created successfully.",
          // });
          toast.success(
            response?.data?.message
              ? response?.data?.message
              : "Poll created successfully."
          );
          setIsCreatePollPressed(false);
          setIsModalOpen(false);
          refresh(refreshid);
        }
      } catch (error) {
        console.log("Create Poll Error", error);
        if (error?.response?.status === 400) {
          // Toast.fire({
          //   icon: "success",
          //   title: error
          //     ? error?.response?.data?.message?.option[0]
          //     : "The option must have at least 2 items.",
          // });
          setIsCreatePollPressed(false);
           toast.error(
            error?.response?.data?.message?.option[0]
              ? error?.response?.data?.message?.option[0]
              : "The poll must have atleast 2 options"
          );
        }
      }
    } else {
      setIsCreatePollPressed(false);
      return setErrors(validationErrors);
    }

    // if (Object.keys(validationErrors).length > 0) {
    //   return setErrors(validationErrors);
    // } else {
    //   setErrors({});
    //   const data = {
    //     title: question,
    //     end_time: getFutureDateTime(selectTime[0]),
    //     option: optionsArray,
    //     poll_privacy: selectedValue,
    //   };
    //   try {
    //     const response = await axios.post(
    //       `${state?.baseUrl}polls/${groupID}/store`,
    //       data,
    //       { headers: headers }
    //     );
    //     console.log("Create Poll response", response?.data);
    //     if (response?.data?.status === 201) {
    //       Toast.fire({
    //         icon: "success",
    //         title: response?.data?.message
    //           ? response?.data?.message
    //           : "Poll created successfully.",
    //       });
    //       setIsModalOpen(false);
    //       refresh(refreshid);
    //     }
    //   } catch (error) {
    //     console.log("Create Poll Error", error);
    //     if (error?.response?.status === 400) {
    //       Toast.fire({
    //         icon: "success",
    //         title: error
    //           ? error?.response?.data?.message?.option[0]
    //           : "The option must have at least 2 items.",
    //       });
    //     }
    //   }
    // }
  };

  return (
    <Modal
      title="Create New Poll"
      open={isModalOpen}
      // onOk={createPollHandler}
      // okText="Create Poll"
      // closable={false}
      // okButtonProps={{ style: { fontSize: 15, fontFamily: "Nunito" } }}
      onCancel={() => setIsModalOpen(false)}
      maskClosable={false}
      closeIcon={true}
      footer={null}
    >
      <div>
        <Input
          className="questionFiels"
          showCount
          maxLength={100}
          minLength={3}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter Poll Name"
          autoFocus
        />
        {errors && (
          <p
            style={{
              fontSize: 12,
              fontFamily: "Nunito",
              color: "red",
              fontWeight: "500",
              paddingLeft: 12,
              paddingTop: 5,
            }}
          >
            {errors?.question}
          </p>
        )}

        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.88)",
              margin: 0,
              padding: 0,
            }}
          >
            Options
          </p>
        </div>
        {optionsArray.map((item, index) => {
          inputRefs.current[index] = React.createRef();
          return (
            <>
              <div>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 15,
                    marginTop: 10,
                  }}
                >
                  <Input
                    // showCount maxLength={20}
                    onChange={(e) => onChangeHandler(e?.target?.value, index)}
                    placeholder={"Option"}
                    className="jfvhejkd"
                    value={optionsArray[index]}
                    ref={inputRefs.current[index]}
                    autoFocus
                  />
                  {index !== optionsArray?.length - 1 && (
                    <button
                      className=" btn btn-primary "
                      onClick={() => deleteOptionsFieldHandler(index)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                {optionsArray.length < 2 && (
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Nunito",
                      color: "red",
                      fontWeight: "500",
                      paddingLeft: 12,
                      paddingTop: 5,
                    }}
                  >
                    {errors?.option}
                  </p>
                )}
                {errors.optionsCheck && index === optionsArray?.length - 1 && (
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Nunito",
                      color: "red",
                      fontWeight: "500",
                      paddingLeft: 12,
                      paddingTop: 5,
                    }}
                  >
                    {errors?.optionsCheck}
                  </p>
                )}
                {index === optionsArray?.length - 1 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className=" btn btn-primary mt-3"
                      onClick={addOptionsFieldHandler}
                      style={{ fontFamily: "Nunito" }}
                    >
                      Add Option
                    </button>
                  </div>
                )}
              </div>
            </>
          );
        })}

        <div
          style={{
            marginBottom: 10,
            marginTop: 30,
            display: "flex",
            justifyContent: "flex-start",
            gap: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 0,
            }}
          >
            <Radio
              checked={selectedValue === "public"}
              onChange={(e) => setSelectedValue(e.target.value)}
              value="public"
              name="radio-buttons"
              inputProps={{ "aria-label": "public" }}
            />
            <p
              style={{
                fontSize: 18,
                fontWeight: "500",
                fontFamily: "Nunito",
                margin: 0,
                padding: 0,
              }}
            >
              Public
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 0,
            }}
          >
            <Radio
              checked={selectedValue === "private"}
              onChange={(e) => setSelectedValue(e.target.value)}
              value="private"
              name="radio-buttons"
              inputProps={{ "aria-label": "private" }}
            />
            <p
              style={{
                fontSize: 18,
                fontWeight: "500",
                fontFamily: "Nunito",
                margin: 0,
                padding: 0,
              }}
            >
              Private
            </p>
          </div>
        </div>

        <div>
          <FormControl className="w-[60%] mt-4">
            <InputLabel id="demo-multiple-checkbox-label">End Time</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple={false}
              value={selectTime}
              onChange={handleChange}
              input={<OutlinedInput label="Select end time" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {names.map((name, index) => (
                <MenuItem key={index} value={name}>
                  <Checkbox checked={selectTime.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {selectTime[0] == "Custom" ? (
        <div className="mt-2">
          <DatePicker className="p-2 mr-2" onChange={onChange} format={'YYYY/MM/DD'} inputReadOnly/>
          <TimePicker className="p-2" onChange={onChangetime} inputReadOnly/>
          {errors && (
            <p
              style={{
                fontSize: 12,
                fontFamily: "Nunito",
                color: "red",
                fontWeight: "500",
                paddingLeft: 12,
                paddingTop: 5,
              }}
            >
              {errors?.time}
            </p>
          )}
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 10,
          marginTop: 30,
          marginBottom: 0,
        }}
      >
        <button
          className=" btn"
          style={{ fontFamily: "Nunito" }}
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className=" btn btn-primary "
          style={{ fontFamily: "Nunito" }}
          onClick={createPollHandler}
          disabled={isCreatePollPressed ? true : false}
        >
          Create Poll
        </button>
      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
closeOnClick
/>
      <ToastContainer />
    </Modal>
  );
};

export default CreatePoll;
