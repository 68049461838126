import React, { useContext } from 'react';
import { Navigate } from "react-router-dom";
import { GlobalContext } from '../Context/context';

const ProtectedRoute = ({ user_Token, children }) => {
    let { state, dispatch } = useContext(GlobalContext);
    
    if (state.isLogin === true || user_Token?.length ) {
       return children
    } else {
        return <Navigate to="/login" replace />;
    }
    // return children;
}

export default ProtectedRoute;