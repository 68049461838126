import React, { useState, useContext, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import className from "../signup/Signup.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import InfoIcon from "@mui/icons-material/Info";
import { Formik } from "formik";
import image from "../../../assets/loginpage.png";
import NumbersIcon from "@mui/icons-material/Numbers";
import { InputAdornment } from "@material-ui/core";
import { GlobalContext } from "../../../Context/context.js";
import Loader from "../../../assets/loader.gif";
import Spinner from "react-spinner-material";
import "react-toastify/dist/ReactToastify.css";
import './ForgotPassword.css'
// CommonJS
const CreatePassword = () => {
  // const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const myInterval = useRef(null);

  const userEmail = location.state?.userEmail || null;

  let { state, dispatch } = useContext(GlobalContext);
  const [otpCode, setOTP_Code] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [counter, setCounter] = useState(299);
  const [isRunning, setIsRunning] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  // const [user_Token, setUserToken] = useState(localStorage.getItem("token"));

  let minutes = 0;
  let seconds = 0;
  if (counter) {
    minutes = Math.floor((counter % 3600) / 60);
    seconds = Math.floor(counter % 60);
  }

  useEffect(() => {
    return () => clearInterval(myInterval.current);
  }, []);

  useEffect(() => {
    if (isRunning && counter !== 0) {
      myInterval.current = setInterval(
        () => setCounter((counter) => counter - 1),
        1000
      );
    }
  }, [isRunning]);

  useEffect(() => {
    setIsRunning(true);
  }, []);

  useEffect(() => {
    if (counter === 0) {
      clearInterval(myInterval?.current);
      myInterval.current = null;
      setIsRunning(false);
    }
  }, [counter]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  //Login Function
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {};

    if (!otpCode) {
      validationErrors.phone_number = "Phone number is required";
    } else if (otpCode?.length < 6) {
      validationErrors.phone_number =
        "Phone number must be at least 8 characters long";
    }

    if (!password) {
      validationErrors.password = "Password is required";
    } else if (password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    }

    if (!confirmPassword) {
      validationErrors.confirm_password = "Password is required";
    } else if (password !== confirmPassword) {
      validationErrors.confirm_password = "Passwords does not match";
    }

    if (Object.keys(validationErrors).length > 0) {
      return setErrors(validationErrors);
    } else {
      setErrors({});
      const data = {
        otp_code: otpCode,
        password: password,
        email: userEmail,
      };
      console.log("data", data);
      try {
        setLoader(true);
        const createPassword_URL = `${state.baseUrl}verify/otp`;

        const response = await axios.put(createPassword_URL, data);
        console.log("Create password response", response);

        if (response?.data?.status === 200) {
          //   Toast.fire({
          //     icon: "success",
          //     title: response?.data?.message
          //       ? response?.data?.message
          //       : "Password is successfully updated.",
          //   });
          toast.success(
            response?.data?.message
              ? response?.data?.message
              : "Password is successfully updated."
          );
          setLoader(false);
          navigate("/login");
          setOTP_Code("");
          setPassword("");
          setConfirmPassword("");
        }
      } catch (error) {
        console.log("CreatePassword Error", error);
        setLoader(false);
        // Toast.fire({
        //   icon: "error",
        //   title: error?.response?.data?.message,
        // });
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const resendCodeHandler = async () => {
    console.log("Hello", userEmail);
    if (!userEmail) {
      navigate("/forget-password");
    } else {
      try {
        setResendLoader(true);
        const forget_URL = `${state.baseUrl}forgot/email`;

        const response = await axios.post(forget_URL, { email: userEmail });
        console.log(
          "Forget Password response=====================",
          response?.data
        );
        if (response?.data?.status === 200) {
          setCounter(299);
          setIsRunning(true);
          setResendLoader(false);
          //   Toast.fire({
          //     icon: "success",
          //     title: `${response?.data?.message ? response?.data?.message : ""}`,
          //   });
          toast.success(response?.data?.message ? response?.data?.message : "");
        }
      } catch (error) {
        console.log("Forget Error", error);
        setResendLoader(false);
        if (error?.response?.data?.status === 401) {
          //   Toast.fire({
          //     icon: "error",
          //     title: `${error?.response?.data?.message}`,
          //   });
          toast.error(error?.response?.data?.message);
        }
      }
    }
  };

  return (
    <>
      <div className="HA_main_div_of_from">
        {/* <img className="HA_main_div_scnd mb-none" src={image} /> */}
        <div className="HA_form_main_div">
          <p className="HA_from_heading_text1">Create Password</p>

          <form id="loginForm" onSubmit={handleSubmit}>
            <TextField
              className="formfields"
              label="OTP Code"
              type="number"
              value={otpCode}
              onChange={(event) => setOTP_Code(event.target.value)}
              error={!!errors.phone_number}
              helperText={errors.phone_number}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NumbersIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className="formfields"
              label="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="formfields"
              label="Confirm Password"
              type="confirmPassword"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              error={!!errors.confirm_password}
              helperText={errors.confirm_password}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>

          <div>
            {counter > 0 && (
              <p
                style={{
                  fontSize: 15,
                  color: counter > 10 ? "blue" : "red",
                  fontFamily: "sans-serif",
                  marginBottom: 10,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {counter &&
                  counter > 0 &&
                  `Code will expire in   ${minutes}:${seconds}`}
              </p>
            )}
          </div>

          <div className="HA_from_main_div_btn">
            {counter > 0 ? (
              // <Button
              //   className="HA_from_login_btn_div"
              //   variant="contained"
              //   color="primary"
              //   type="submit"
              //   form="loginForm"
              // >
              //   {loader ? (
              //     <Spinner
              //       size={12}
              //       spinnerColor={"#333333"}
              //       spinnerWidth={2}
              //       visible={loader}
              //     />
              //   ) : (
              //     "Create"
              //   )}
              // </Button>
              <div className="login-btn-container">
              <button className="login-btn" form="loginForm" >
              {/* {loader ? (
                <Spinner
                  size={5}
                  spinnerColor="#fff"
                  spinnerWidth={2}
                  visible={loader}

                />
              ) : ( */}
                 Create
              {/* )} */}
              </button>
            </div>
            ) : (
              // <Button
              //   className="HA_from_login_btn_div"
              //   variant="contained"
              //   color="primary"
              //   type="submit"
              //   // form="loginForm"
              //   onClick={resendCodeHandler}
              // >
              //   {resendLoader ? (
              //     <Spinner
              //       size={12}
              //       spinnerColor={"#333333"}
              //       spinnerWidth={2}
              //       visible={loader}
              //     />
              //   ) : (
              //     "Resend"
              //   )}
              // </Button>
              <div className="login-btn-container">
              <button className="login-btn" form="loginForm" onClick={resendCodeHandler}>
              {/* {loader ? (
                <Spinner
                  size={5}
                  spinnerColor="#fff"
                  spinnerWidth={2}
                  visible={loader}

                />
              ) : ( */}
                 Resend
              {/* )} */}
              </button>
            </div>
            )}
          </div>

          <div
            style={{
              // display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <p
              style={{
                fontSize: 15,
                color: counter > 0 ? "blue" : "red",
                fontFamily: "sans-serif",
                marginBottom: 10,
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {counter === 0
                ? "Your OTP code has been expired!"
                : "If you didn't recive a code?"}
            </p>

            {counter > 0 && (
              <div className="HA_from_main_div_btn">
                {/* <Button
                  className="HA_from_login_btn_div"
                  variant="contained"
                  color="primary"
                  type="submit"
                  // form="loginForm"
                  onClick={resendCodeHandler}
                >
                  {resendLoader
                    ? // <Spinner
                      //     size={12}
                      //     spinnerColor={"#333333"}
                      //     spinnerWidth={2}
                      //     visible={loader}
                      // />
                      "Wait..."
                    : "Resend"}
                </Button> */}
                 <div className="login-btn-container">
              <button className="login-btn" form="loginForm" onClick={resendCodeHandler}>
              {/* {loader ? (
                <Spinner
                  size={5}
                  spinnerColor="#fff"
                  spinnerWidth={2}
                  visible={loader}

                />
              ) : ( */}
                 Resend
              {/* )} */}
              </button>
            </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CreatePassword;
