import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import PollIcon from "@mui/icons-material/Poll";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import "./PollComponent.css";
import { GlobalContext } from "../../Context/context";
import { Modal } from "antd";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";
import PollVoterList from "./PollVoterList";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";

function PollComponent({
  data,
  item,
  index,
  isRefreshPollData,
  onToggleNotification,
}) {
  const { state, dispatch } = useContext(GlobalContext);
  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [opacity, setOpacity] = useState(1);
  const [voteCasted,setVoteCasted] = useState(false)
  const [isShowVoterList, setIsShowVoterList] = useState(false);
  const [voterData, setVoterData] = useState([]);

  console.log("poll data ==>> ", data);
  console.log("poll item ==>> ", item);

  // console.log("userName :>> ", userName);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
  });

  let timeDiff = 0;

  const currentTimeFormatter = () => {
    const currentDate = new Date();
    const momentDate = moment(currentDate);
    const convertedDate = momentDate.format("YYYY-MM-DD HH:mm");
    return convertedDate;
  };

  const CountdownComponent = (endTime, group_id) => {
    const utcTime = moment.utc(endTime);

    const currentTime = moment();
    const timeDifference = utcTime.diff(currentTime);
    const countdownTime = moment.duration(0, "hours").add(0, "minutes");
    const targetTime = moment(currentTime)
      .add(timeDifference, "milliseconds")
      .add(countdownTime);

    const renderer = ({ days, hours, minutes, seconds }) => {
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        isRefreshPollData(group_id);
      }

      return (
        <p
          className="pollingTimeText"
          style={{
            marginRight: 5,
            fontSize: 12,
            fontFamily: "Nunito",
            fontWeight: "600",
            color:
              days === 0 && hours === 0 && minutes === 0 && seconds === 0
                ? "red"
                : "grey",
            marginBottom: 0,
          }}
        >
          {/* {minutes === 0 && seconds === 0 ? "Polling Time has been expired" : "Time Remaining: "} */}

          {/* {"Time Remaining: "} */}

          {days === 0 && hours === 0 && minutes === 0 && seconds === 0
            ? "Polling Time expired"
            : "Time Remaining: "}
          {days > 0 ? days + " Days" : null}
          {hours ? hours + ":" : null}
          {minutes ? minutes + ":" : null}
          {seconds ? seconds : null}
        </p>
      );
    };

    return (
      <div>
        <Countdown date={targetTime} renderer={renderer} />
      </div>
    );
  };

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const pollCast = async (option, poll_id, group_Id) => {
    toast.dismiss();
    if(voteCasted){
      // toast.success("Poll already casted successfully");
      return
    }
    setVoteCasted(true)
    try {
      const data = {
        option: option,
      };
      const response = await axios.post(
        `${state.baseUrl}poll/${poll_id}/casting`,
        data,
        { headers: headers }
      );
      console.log("Vote Casting Response", response?.data);
      if (response?.data?.status === 200) {
        isRefreshPollData(group_Id);
        // setIsShowPollVotingModal(false);
        // Toast.fire({
        //   icon: "success",
        //   title: response?.data?.message
        //     ? response?.data?.message
        //     : "Poll cast successfully",
        // });
        toast.success(
          response?.data?.message
            ? response?.data?.message
            : "Poll cast successfully"
        );
      }
    } catch (error) {
      setVoteCasted(false)
      console.log("Vote Casting Error", error);
      // setIsShowPollVotingModal(false);
      if (error?.response?.data?.status === 400) {
        // Toast.fire({
        //   icon: "error",
        //   title: error?.response?.data?.message
        //     ? error?.response?.data?.message
        //     : "Poll time has expired. You cannot cast your vote.",
        // });
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Poll time has expired. You cannot cast your vote."
        );
      }
    }
  };

  const touchableOpacityHandler = () => {
    setOpacity(0.2);
    setTimeout(() => {
      setOpacity(1);
    }, 300);
  };

  return (
    <>
      <div className="flex flex-col gap-10"></div>
      {data?.length !== 0 ? (
        <div
          key={index}
          style={{
            width: 380,
            marginBottom: 30,
            opacity: item?.status === "expired" ? 1 : 1,
          }}
          className="pollMainContainer"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 5,
            }}
          >
            <div className="iconAndUserNameContainer">
              <PollIcon style={{ color: "blue", fontSize: 26 }} />
              <h6
                className="pollFromText"
                style={{
                  fontSize: 14,
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  // marginTop: 5,
                  marginBottom: 0,
                }}
              >
                Poll from {item?.poll_admin ? item?.poll_admin : null}
              </h6>
            </div>

            <h6
              className="pollingTimeText"
              style={{
                fontSize: 12,
                fontFamily: "Nunito",
                fontWeight: "500",
                marginTop: 8,
                color: item?.status === "expired" ? "red" : "grey",
                // marginLeft: 7
              }}
            >
              {item?.status === "expired"
                ? "Polling Time expired"
                : CountdownComponent(item?.end_time, item?.group_id)}
            </h6>

            {item?.status != "expired" && item?.notification_setting && (
              <div className="dropdown">
                <MoreHorizIcon
                  className="dropdown-toggle h-dropdown"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  color="primary"
                />

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => onToggleNotification(item)}
                  >
                    <p
                      className="dropdown-item-text"
                      style={{ margin: 0, padding: 0 }}
                    >
                      {/* Turn on/off result announcement notification */}
                      {item?.notification_setting === "enable"
                        ? "Turn off result announcement notification"
                        : "Turn on result announcement notification"}
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className="pollBadgeContainer">
            <div className="badge">
              <p className="badgeText">{item?.poll_privacy} Poll</p>
            </div>
          </div>

          <div className="pollTextContainer">
            <p className="pollText capitalize">
              {item?.title ? item?.title : ""}
            </p>
          </div>
          <div>
            {item?.option?.map((options, optionIndex) => {
              // console.log('options', options);

              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 6,
                      // opacity: item?.status === "expired" ? 0.2 : 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "90%",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "10",
                          // backgroundColor: "red",
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        {item?.status === "expired" ? (
                          <div style={{ width: 30, height: 30 }}></div>
                        ) : (
                          <div
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 50,
                              border: "1px solid blue",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              opacity: opacity,
                              // margin: 0,
                              // padding: 0,
                            }}
                            className="custom-button"
                            onClick={() => {
                              touchableOpacityHandler();
                              // setIsShowPollVotingModal(true);
                              pollCast(options?.name, item?.id, item?.group_id);
                            }}
                            
                          >
                            <p
                              style={{
                                width: 20,
                                height: 20,
                                borderRadius: 50,
                                margin: 0,
                                padding: 0,
                                background:
                                  item?.useroption === options?.name
                                    ? "blue"
                                    : "",
                              }}
                            ></p>
                          </div>
                        )}
                      </div>

                      <div
                        className="optionListContainer"
                        style={{ minWidth: 100, width: "80%" }}
                      >
                        <p className="listText">{options?.name || ""}</p>
                      </div>
                    </div>

                    <div style={{ width: "10%" }}>
                      <p style={{ margin: 0, padding: 0 }}>
                        {options?.votes || "0"}
                      </p>
                    </div>
                  </div>
                  {options?.votes > 0 && item?.poll_privacy != "private" && (
                    <div className="mb-3 ml-12">
                      <a
                        style={{
                          fontSize: 14,
                          fontFamily: "Nunito",
                          fontWeight: "500",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        href={"#"}
                        onClick={() => {
                          setIsShowVoterList(true);
                          setVoterData(options);
                        }}
                      >
                        View votes
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
            {isShowVoterList && (
              <PollVoterList
                isShowVoterList={isShowVoterList}
                onClose={setIsShowVoterList}
                data={voterData ? voterData : []}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: 30,
              flexDirection:'row'
            }}
          >
            <p
              className="votedText"
              style={{
                opacity: item?.status === "expired" ? 1 : 1,
                margin: 0,
                padding: 0,
              }}
            >
              Total Number of votes: {item?.result > 0 ? item?.result : "0"}
            </p>

            {/* Check Admin and show download file option */}
            {
              // console.log(item?.admin_name.includes(userName))
              // console.log(userName)
              console.log('USER NAME ====>>>>> ',userName)
            }
            {item?.status == "expired" &&
              item.result > 0 &&
              item?.poll_privacy != "private" &&
              item?.admin_name?.includes(userName) && (
                // <div
                //   style={{
                //     // display: "flex",
                //     // alignItems: "center",
                //     // justifyContent: "center",
                //   }}
                // >
                  <a
                    href={item?.filename}
                    className="downloadResultText"
                    style={
                      {
                        fontSize: 14,
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "blue",
                      }
                    }
                    download={item?.filename}
                  >
                    Download Result
                  </a>
                // </div>
              )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: 20,
              color: "grey",
              fontFamily: "Nunito",
              fontWeight: "600",
            }}
          >
            No Poll Available
          </p>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default PollComponent;
