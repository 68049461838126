import "./chat.css";
import dummuData from "./dummyData";
import React, { useRef } from "react";
import { Link, useMatch } from "react-router-dom";
import Loader from "../assets/Rolling-1s-200px.gif";
import moment from "moment";
import welcome from "../assets/welcome.svg";
import { useMemo, useCallback, useEffect, useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AddGroupIcon from "@mui/icons-material/AddCircleOutline";
import AddPollIcon from "@mui/icons-material/Add";
import AddUserIcon from "@mui/icons-material/PersonAddAlt";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CryptoJS from "crypto-js";
import { AES, enc } from "crypto-js";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  MainContainer,
  Sidebar,
  ConversationList,
  Conversation,
  Avatar,
  Search,
  ChatContainer,
  ConversationHeader,
  MessageGroup,
  Message,
  MessageSeparator,
  MessageList,
  MessageInput,
  TypingIndicator,
  InfoButton,
  VideoCallButton,
  VoiceCallButton,
  StarButton,
  ExpansionPanel,
  Button,
} from "@chatscope/chat-ui-kit-react";
// import HashImage from "../assets/hashtag.png";
import HashImage from "../assets/user-image.png";
import {
  useChat,
  ChatMessage,
  MessageContentType,
  MessageDirection,
  MessageStatus,
} from "@chatscope/use-chat";
import { MessageContent, TextContent, User } from "@chatscope/use-chat";
import { Modal } from "antd";
import NavbarChat from "./Navbar";
import SwipeableTemporaryDrawer from "./buttonSlider/SliderBtn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate, useLocation } from "react-router-dom";
import ModalImage from "react-modal-image";
import { GlobalContext } from "../Context/context";
import { message } from "antd";
import { Console, log } from "console";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PollComponent from "./poll/PollComponent";
import CreatePoll from "./poll/CreatePoll";
import zIndex from "@mui/material/styles/zIndex";
import CreateGroups from "./groups/CreateGroups";
import GroupImage from "../assets/group.jpeg";
import UserListGroupDetail from "./groups/UserListGroupDetail";
import InviteUserForGroup from "./InviteUserForGroup";
import Swal from "sweetalert2";
import { isatty } from "tty";
import GroupDeleteModal from "./groups/GroupDeleteModal";
import PersonIcon from "@mui/icons-material/Person";
import UserImage from "../assets/userImage.jpeg";
import RemoveUserByAdminModal from "./RemoveUserByAdminModal";
import EditUserByAdminModal from "./EditUserByAdminModal";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import GroupLeaveModal from "./groups/GroupLeaveModal";
import { sendReloadWebView } from "./webViewFunctions";

export const Chat = ({ user,tab }: { user: User; tab?:string }) => {
  let previousDate: any = null;
  // console.log('====',tab,'======');
  
  const navigate = useNavigate();

  // const msgListRef = useRef();
  const msgListRef = useRef<HTMLDivElement>(null);

  const [isScrollbarActive, setIsScrollbarActive] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [notificationState, setNotificationState] = useState(() => {
    const storedState = localStorage.getItem("notificationState");
    return storedState ? JSON.parse(storedState) : {};
  });

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [sidebarRightVisible, setSidebarRightVisible] = useState(false);

  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [user_id, setUserId] = useState(localStorage.getItem("userId"));

  // console.log("user_id is ==> ", user_id);

  const [sidebarStyle, setSidebarStyle] = useState({});
  const [sidebarRightStyle, setSidebarRightStyle] = useState({});

  const [chatContainerStyle, setChatContainerStyle] = useState({});

  const [messages, setMessages] = useState<any>([]);

  const [activeName, setActiveName] = useState("");

  const [activeAvatar, setActiveAvatar] = useState("");

  const [notificationChannelId, setNotificationChannelId] = useState("");

  const [loading, setLoading] = useState(false);

  const [conversationContentStyle, setConversationContentStyle] = useState({});

  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});

  const [activeConvo, setActiveConvo] = useState(null);

  const handleBackClick = () => {
    setSidebarVisible(!sidebarVisible);
    setActiveConvo(null);
    // getGroupInvitationsData();
  };

  const [selectedChats, setSelectedChats] = useState(false);

  let { state, dispatch } = useContext<any>(GlobalContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeNot, setActiveNot] = useState("");

  const [viewerVisible, setViewerVisible] = useState(false);

  const [viewerIndex, setViewerIndex] = useState(0);

  const [viewerImages, setViewerImages] = useState<string[]>([]);
  const [opacity, setOpacity] = useState(1);
  const [myGroupOpacity, setMyGroupOpacity] = useState(1);
  const [followingGroupOpacity, setFollowGroupOpacity] = useState(1);
  const [inviteGroupOpacity, setInviteGroupOpacity] = useState(1);
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [create_Or_UpdateGroupType, setCreate_Or_UpdateGroupType] =
    useState<String>("");
  const [isActiveGroups, setIsActiveGroups] = useState("");
  const [groupData, setGroupData] = useState<any>([]);
  const [groupClicked,setGroupClicked] = useState(false)
  console.log("groupData ==> ", groupData);
  const [invitationData, setInvitationData] = useState<any>([]);
  const [groupAllDetailsData, setGroupAllDetailsData] = useState<any>(null);

  // console.log("groupAllDetailsData .>>> ", groupAllDetailsData);
  // console.log("invitationData .>>> ", invitationData);

  const [isShowGroupDetail, setIsShowGroupDetail] = useState<any>(false);
  const [isAddUser, setIsAddUser] = useState<Boolean>(false);
  const [inviteAcceptAndRejectModal, setInviteAcceptAndRejectModal] =
    useState(false);
  const [inviteUser_ID, setInviteUser_ID] = useState(null);
  const [myGroup_uuid, setMyGroup_uuid] = useState(null);
  const [polldata, setpolldata] = useState<any>([]);
  const [refreshid, setrefreshid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDeleteGroupModal, setIsShowDeleteGroupModal] = useState(false);
  const [isShowLeaveGroupModal, setIsShowLeaveGroupModal] = useState(false);
  const [isShowRemoveUserModal, setIsShowRemoveUserModal] = useState(false);
  const [isShowEditUserModal, setIsShowEditUserModal] = useState(false);
  const [removeUserData, setUserRemoveData] = useState({ name: "", pivot: {} });
  const [editUserData, setEditUserData] = useState({
    name: "",
    pivot: { user_id: null, group_id: null },
  });

  const [queryParams, setQueryParams] = useState<{ [key: string]: any }>({});

  // console.log("query params =====>> ", queryParams?.data?.type);

  // console.log("editUserData ==>> ", editUserData);

  // console.log("searchQuery ===> ", searchQuery);
  // console.log("polldata ===> ", polldata);

  console.log("isActiveGroups ==>> ", isActiveGroups);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  const touchableOpacityHandler = () => {
    setOpacity(0.2);
    setTimeout(() => {
      setOpacity(1);
    }, 300);
  };

  const showCreateGroup = (item: any) => {
    touchableOpacityHandler();
    setCreate_Or_UpdateGroupType(item);
    setIsCreateGroup(true);
    // getMyGroupData(true)
  };

  const handleImageClick = (imageSrc: string) => {
    setViewerVisible(true);
    setViewerIndex(0);
    setViewerImages([imageSrc]);
  };

  const encryptId = (id: any) => {
    const encrypted = AES.encrypt(
      id.toString(),
      "secret passphrase"
    ).toString();

    return enc.Hex.stringify(enc.Utf8.parse(encrypted));
  };
  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };
  const headers1 = {
    headers: {
      Authorization: `Bearer ${user_Token}`,
    },
  };

  const handleConversationClick = useCallback(
    async (channelId) => {
      // setActiveConvo(channelId);
      setIsScrollbarActive(true);

      setSelectedChats(true);
      setLoading(true);
      // setNotificationChannelId(channelId);
      // setActiveNot("");

      try {
        if (sidebarVisible) {
          setSidebarVisible(false);
        }

        const response = await axios.get(
          `${state.baseUrl}api/channel/message/${channelId}`,

          {
            headers: headers,
          }
        );
        setMessages(response.data.data);
        // console.log(response.data.channel_details.name);
        setActiveName(response.data.channel_details.name);
        setActiveAvatar(response.data.channel_details.icon);

        // setActiveNot(response.data.notification);

        setTimeout(() => {
          var divList = document.getElementsByClassName("theMEssage");
          var lastDiv = divList[divList.length - 1];
          lastDiv.scrollIntoView();
        }, 2000);

        // console.log(msgListRef.current.scrollToBottom);

        // msgListRef.current.scrollToBottom("auto");

        // Process the response as needed
      } catch (error) {
        console.log("Error fetching messages:", error);
      }
      setLoading(false);
    },
    [sidebarVisible, setSidebarVisible]
  );

  const handleLikeClick = async (channelId: any, status: any) => {
    const updatedState = { ...notificationState };
    const enable = !updatedState[channelId]; // Toggle the notification setting

    // Send the notification setting to the server
    // await sendNotification(channelId, status);

    // Update the local notification state based on the response
    updatedState[channelId] = enable;
    setNotificationState(updatedState);
    localStorage.setItem("notificationState", JSON.stringify(updatedState));
  };

  const getGroupInvitationsData = async () => {
    setGroupClicked(false)
    setIsShowGroupDetail(false);
    setInviteGroupOpacity(0.2);
    setTimeout(() => {
      setInviteGroupOpacity(1);
    }, 300);
    setIsActiveGroups("Invitation");
    try {
      const response = await axios.get(`${state.baseUrl}user/invitation`, {
        headers: headers,
      });
      console.log("Group Invitation Data Response", response?.data);
      if (response?.data?.status === 200) {
        setGroupData(response?.data?.data);
        setInvitationData(response?.data?.data);
        setLoading(false);
        // setActiveName(response?.data?.data[0]?.group_id?.name);
        // setActiveAvatar(response?.data?.data[0]?.group_id?.profile_picture);
        // groupAllDetailDataHandler(response?.data?.data[0]?.group_id?.uuid);
      }
    } catch (error) {
      console.log("Group Invitation Data Error", error);
    }
  };

  const getFollowingGroupsData = async () => {
    setGroupClicked(false)
    setIsShowGroupDetail(false);
    setFollowGroupOpacity(0.2);
    setTimeout(() => {
      setFollowGroupOpacity(1);
    }, 300);
    setIsActiveGroups("FollowingGroup");
    try {
      const response = await axios.get(`${state.baseUrl}all/user/group`, {
        headers: headers,
      });
      console.log("Get Following Groups Data response", response?.data);
      if (response?.data?.status === 200) {
        // getPollData(response?.data?.data[0]?.id);
        setGroupData(response?.data?.data);
        // setActiveName(response?.data?.data[0]?.name);
        // setActiveAvatar(response?.data?.data[0]?.profile_picture);
        // groupAllDetailDataHandler(response?.data?.data[0]?.uuid);
      }
    } catch (error) {
      console.log("getGroupsData Error: ", error);
    }

    setLoading(false);
  };

  const getMyGroupData = async (itv: any) => {
    
    
    console.log("getMyGroupData called...");
    setIsShowGroupDetail(false);
    setIsActiveGroups("MyGroup");
    // window.location.reload();

    setMyGroupOpacity(0.2);
    setTimeout(() => {
      setMyGroupOpacity(1);
    }, 300);

    if (itv) setLoading(true);
    try {
      const response = await axios.get(`${state.baseUrl}all/admin/group`, {
        headers: headers,
      });
      console.log("Get my group Data response", response?.data?.data);
      if (response?.data?.status === 200) {
        setGroupData(response?.data?.data);
        // setGroupClicked(response?.data?.data)
        // console.log("MyGroup_uuid ==>>> ", response?.data?.data[0]?.uuid);
        // setMyGroup_uuid(response?.data?.data[0]?.uuid);
        // setLoading(false);
        // setActiveName(response?.data?.data[0]?.name);
        // setActiveAvatar(response?.data?.data[0]?.profile_picture);
        // // groupAllDetailDataHandler(response?.data?.data[0]?.uuid);
        // getPollData(response?.data?.data[0]?.id);
        // setrefreshid(response?.data?.data[0]?.id);
      }
    } catch (error) {
      console.log("Get my group Data error", error);
    }
  };

  const groupAllDetailDataHandler = async (uuid: any) => {
    try {
      const response = await axios.get(`${state.baseUrl}groupdetails/${uuid}`, {
        headers: headers,
      });
      console.log("GroupAllDetailDataHandler response", response?.data);
      if (response?.data?.status === 200) {
        setGroupAllDetailsData(response?.data);
      }
    } catch (error) {
      console.log("GroupAllDetailDataHandler Error", error);
    }
  };

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  // Access individual query parameters
  const parameterValue = queryParam.get("data");
  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    
      if (parameterValue == "invitation") {
        console.log("parameter value has invitation...");
        getGroupInvitationsData();
      }

    // if (parameterValue) handleConversationClick(parameterValue);
  }, [parameterValue]);

  const handleAssignUserAsAdmin = async () => {
    // alert("assign user as admin..");
    const user_id = editUserData?.pivot?.user_id;
    const group_id = editUserData?.pivot?.group_id;

    try {
      const response = await axios.post(
        `${state.baseUrl}assing/group/admin/${group_id}/${user_id}`,
        {},
        { headers: headers }
      );

      console.log("response data (user to admin) ===>> ", response);

      if (response?.data?.status === 200) {
        setIsShowEditUserModal(false);
        toast.success(response?.data?.message ? response?.data?.message : "");
      }
    } catch (error: any) {
      console.log("error (user to admin) ==>> ", error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "An error occured"
      );
    }
  };

  useEffect(() => {
    let isNewMessage = false; // Flag to track new messages

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${state.baseUrl}api/channel/message/${activeConvo}`,
          { headers }
        );

        if (response.data.data.length > messages.length) {
          // New message(s) received
          isNewMessage = true;
          setMessages(response.data.data);
        }
      } catch (error) {
        console.log("Error fetching messages:", error);
      }
    };
  }, [activeConvo, messages.length, state.baseUrl, headers]);

  const updateInviteCount = async () => {
    try {
      const response = await axios.get(`${state.baseUrl}user/invitation`, {
        headers: headers,
      });
      // console.log("Group Invitation Data Response", response?.data);
      if (response?.data?.status === 200) {
        setInvitationData(response?.data?.data);
      }
    } catch (error) {
      console.log("Group Invitation Data Error", error);
    }
  };

  useEffect(() => {
    updateInviteCount();
  }, []);

  useEffect(() => {
    // getMyGroupData(true);

    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "320px",
        maxWidth: "100%",
      });
      setConversationContentStyle({
        display: "flex",
      });
      setConversationAvatarStyle({
        marginRight: "1em",
      });
      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
      setChatContainerStyle({});
    }
  }, [
    sidebarVisible,
    setSidebarVisible,
    setConversationContentStyle,
    setConversationAvatarStyle,
    setSidebarStyle,
    setChatContainerStyle,
    // isCreateGroup,
  ]);

  useEffect(() => {
    // getMyGroupData(true);

    if (sidebarRightVisible) {
      setSidebarRightStyle({
        display: "flex",
        flexBasis: "auto",

        // width: "320px",
        // maxWidth: "100%",
      });

      setSidebarVisible(false);

      // setSidebarStyle({
      //   display: "none",
      // });

      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarRightStyle({});
      setChatContainerStyle({});
    }
  }, [
    sidebarRightVisible,
    sidebarVisible,
    setSidebarStyle,
    setSidebarRightVisible,
    setSidebarRightStyle,
    setChatContainerStyle,
  ]);

  // update channels
  // useEffect(() => {
  //   getMyGroupData(false);
  // }, []);

  // Get all chat related values and methods from useChat hook
  const {
    currentMessages,
    conversations,
    activeConversation,
    setActiveConversation,
    sendMessage,
    getUser,
    currentMessage,
    setCurrentMessage,
    sendTyping,
    setCurrentUser,
  } = useChat();

  // Get current user data
  const [currentUserAvatar, currentUserName] = useMemo(() => {
    if (activeConversation) {
      const participant =
        activeConversation.participants.length > 0
          ? activeConversation.participants[0]
          : undefined;

      if (participant) {
        const user = getUser(participant.id);

        if (user) {
          // console.log("user", user);
          return [<Avatar src={user.avatar} />, user.username];
        }
      }
    }

    return [undefined, undefined];
  }, [activeConversation, getUser]);

  interface WindowWithWebView extends Window {
    ReactNativeWebView?: {
      postMessage(message: string): void;
    };
  }
  const logout = () => {
    // dispatch({
    //   type: "USER_LOGOUT",
    // });
    localStorage.removeItem("token");

    dispatch({
      type: "USER_LOGOUT",
    });
    // const windowWithWebView = window as WindowWithWebView;
    // if (windowWithWebView.ReactNativeWebView) {
    //   windowWithWebView.ReactNativeWebView.postMessage("logout");
    // }
    navigate("/login");
    window.history.replaceState(null, "", "/");
  };

  const AccountSetting = () => {
    navigate("/account-setting");
  };

  const inviteAcceptHandler = async (type: any) => {
    const endPoint = type === "Accept" ? "accept" : "reject";
    try {
      const responsee = await axios.get(
        `${state.baseUrl}user/invitation/${inviteUser_ID}/${endPoint}`,
        { headers: headers }
      );
      console.log("Invite Accept Response", responsee?.data);
      if (responsee?.data?.status === 200) {
        // Toast.fire({
        //   icon: "success",
        //   title: responsee?.data?.message
        //     ? responsee?.data?.message
        //     : "You can assingned in group",
        // });
        toast.success(responsee?.data?.message);
        setInviteAcceptAndRejectModal(false);
        setIsActiveGroups("Invitation");
        handleBackClick();
        getGroupInvitationsData();
        updateInviteCount();

        // Send a reload signal to the react native webview if available, otherwise reload web page
        // sendReloadWebView();
      }
    } catch (error: any) {
      console.log("Invite Accept Error", error);
      setInviteAcceptAndRejectModal(false);
      toast.error(error?.response?.data?.message);
      setIsActiveGroups("Invitation");
      handleBackClick();
      getGroupInvitationsData();
      updateInviteCount();
    }
  };

  const getPollData = async (id: number) => {
    console.log("group_Id :>> ", id);
    try {
      const response = await axios.get(`${state.baseUrl}polls/${id}`, {
        headers: headers,
      });
      if (response.status === 200) {
        console.log("poll data response", response?.data);
        setpolldata(response?.data?.data);
      }
    } catch (error: any) {
      console.log("poll data response Error", error);
    }
  };

  const handleLeaveGroup = async () => {
    const group_id = groupAllDetailsData?.group?.id;

    try {
      const response = await axios.get(
        `${state.baseUrl}groupleave/${group_id}/${user_id}`,
        { headers: headers }
      );

      console.log("leave group response ===> ", response);

      if (response?.data?.status === 200) {
        setIsShowLeaveGroupModal(!isShowLeaveGroupModal);
        toast.success(response?.data?.message ? response?.data?.message : "");
        window.location.reload();
        getFollowingGroupsData();
      }

      if (response?.data?.status === 404) {
        toast.error("User not found");
      }
    } catch (error) {
      console.log("error leave group: ", error);
      toast.error("Error leaving group");
    }
  };

  async function handleToggleNotification(itemData: any) {
    console.log("poll item click toggle notification ==>> ", itemData);

    try {
      const payload = {
        notification:
          itemData?.notification_setting === "enable" ? "disable" : "enable",
        poll_id: itemData?.id,
      };

      console.log("payload (poll notification) : ", payload);

      const response = await axios.put(
        `${state.baseUrl}poll/notification`,
        payload,
        { headers: headers }
      );

      console.log("response data (poll notification) : ", response?.data);

      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        getPollData(itemData?.group_id);
      }
    } catch (error: any) {
      // Handle errors from the API request
      console.error("Error (poll notification) : ", error);
      if (error?.response?.data?.message?.hasOwnProperty("notification")) {
        toast.error(error?.response?.data?.message?.notification[0]);
      }
      if (error?.response?.data?.message?.hasOwnProperty("poll_id")) {
        toast.error(error?.response?.data?.message?.poll_id[0]);
      }
    }
  }

  useEffect(() => {
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const paramsObject: { [key: string]: any } = {};
    // urlSearchParams.forEach((value, key) => {
    //   paramsObject[key] = value;
    // });
    // setQueryParams(paramsObject);
    // console.log('THis is new build');
    
    const queryParams = new URLSearchParams(window.location.search);
    // Access individual query parameters
    const parameterValue = queryParams.get("data");


    console.log(
      "parameter value ==================================>>>> ",
      parameterValue
    );
    // alert(parameterValue);
    if(tab){
      getGroupInvitationsData();
    }else if (parameterValue == "invitation") {
      console.log("parameter value has invitation...");
      
      getGroupInvitationsData();
    }else if(parameterValue == 'followingGroup'){
      getFollowingGroupsData()
    }
    else{
      getMyGroupData(false);
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        position: "relative",
      }}
    >
      <MainContainer responsive className="border-0  main-container">
        <Sidebar
          position="left"
          scrollable={true}
          style={sidebarStyle}
          id="side-bar"
          className={`scrollbar-container cs-sidebar cs-sidebar--left sidebar ps ${
            isScrollbarActive ? "ps--active-x" : "sidebar"
          }`}
        >
          {/* <NavbarChat /> */}
          <div
            style={{
              borderBottom: "1px solid #cedbe3",
              // paddingLeft: 15,
              // paddingRight: 10,
              // height: 74,
              // display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            {/* <AddGroupIcon style={{ color: "blue", fontSize: 46 }} /> */}

            {isActiveGroups === "MyGroup" && (
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 50,
                  background: "blue",
                  // display: isActiveGroups !== "MyGroup" ? "none" : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: 30,
                  right: 30,
                  zIndex: 99,
                  opacity: opacity,
                  cursor: "pointer",
                }}
                onClick={() => showCreateGroup("Create")}
              >
                <AddPollIcon style={{ color: "white", fontSize: 30 }} />
              </div>
            )}

            {isCreateGroup && (
              <CreateGroups
                isModalOpen={isCreateGroup}
                setIsCreateGroup={setIsCreateGroup}
                groupType={create_Or_UpdateGroupType}
                data={groupAllDetailsData?.group}
                onCreateGroupSuccess={() => getMyGroupData(false)}
                onUpdateGroupSuccess={getMyGroupData}
              />
            )}
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 10,
                height: 55,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 25,
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Groups
                </div>
              </div>
              <div className="dropdown">
                <MoreHorizIcon
                  className="dropdown-toggle h-dropdown"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  color="primary"
                />

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li className="dropdown-item" onClick={AccountSetting}>
                    <Link to={`/account-setting`} className="account-setting">
                      Account Setting
                    </Link>
                  </li>
                  <li
                    className="dropdown-item"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                    onClick={logout}
                  >
                    Logout
                    {/* <LogoutIcon /> */}
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                paddingBottom: 10,
                // background: "red"
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  color: isActiveGroups === "MyGroup" ? "grey" : "#0000ff",
                  opacity: myGroupOpacity,
                  cursor: "pointer",
                }}
                onClick={getMyGroupData}
              >
                My Groups
              </div>
              <div
                // className="touchable"
                style={{
                  fontSize: 14,
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  color:
                    isActiveGroups === "FollowingGroup" ? "grey" : "#0000ff",
                  opacity: followingGroupOpacity,
                  cursor: "pointer",
                }}
                onClick={getFollowingGroupsData}
              >
                Following Groups
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  color: isActiveGroups === "Invitation" ? "grey" : "#0000ff",
                  cursor: "pointer",
                  opacity: inviteGroupOpacity,
                }}
                onClick={getGroupInvitationsData}
              >
                Invitations{" "}
                {invitationData?.length > 0
                  ? ` (${invitationData?.length})`
                  : ""}
              </div>
            </div>
          </div>

          {/*Left side bar */}
          {isLoading && <div>Loading...</div>}
          {groupData?.length ? (
            <ConversationList id="muazan" className="conversationList">
              {/* Search Input */}
              <div className="searchContainer">
                <Search
                  className="searchInput"
                  placeholder="Search Groups..."
                  value={searchQuery}
                  onChange={(value: any) => setSearchQuery(value)}
                  onClearClick={() => setSearchQuery("")}
                />
              </div>
              {/* {dummuData */}
              {/* {groupData.filter((item: any) => {
                item.name.includes(searchQuery);
              })} */}
              {groupData
                ?.filter((item: any) => {
                  const itemName = item?.name
                    ? item?.name?.toLowerCase()
                    : item?.group_id?.name
                    ? item?.group_id?.name?.toLowerCase()
                    : "";
                  const query = searchQuery?.toLowerCase();

                  return itemName?.includes(query);
                })
                .map((c: any, index: any) => {
                  // console.log("c ==> ", c);
                  return (
                    <Conversation
                      className={
                        activeConvo === c.id ? "active" : "conversations"
                      }
                      key={c?.group_id?.id ? c?.group_id?.id : c.id}
                      // unreadCnt={c.unread_messages}
                      onClick={() => {
                        setIsShowGroupDetail(false);
                        groupAllDetailDataHandler(
                          c?.group_id?.uuid ? c?.group_id?.uuid : c?.uuid
                        );
                        handleConversationClick(c.id);
                        setrefreshid(c.id);

                        setActiveName(
                          c?.group_id?.name
                            ? c?.group_id?.name
                            : c.name
                            ? c.name
                            : ""
                        );
                        setActiveAvatar(
                          c?.group_id?.profile_picture
                            ? c?.group_id?.profile_picture
                            : c?.profile_picture
                        );
                        if (isActiveGroups === "Invitation") {
                          setInviteAcceptAndRejectModal(true);
                        }
                        setInviteUser_ID(c?.group_id ? c?.id : null);
                        setMyGroup_uuid(c?.uuid ? c?.uuid : null);
                        getPollData(c.id);
                        setGroupClicked(true)
                      }}
                      name={
                        c?.group_id?.name
                          ? c?.group_id?.name
                          : c.name
                          ? c.name
                          : ""
                      }
                      // info={c?.description}
                      info="Yes i can do it for you"
                      // unreadCnt={index === 0 ? 2 : 0}
                      lastSenderName="Emily"
                    >
                      <Avatar
                        // src={c?.profile_picture ? c?.profile_picture : GroupImage}
                        src={
                          c?.group_id?.profile_picture
                            ? c?.group_id?.profile_picture
                            : c?.profile_picture
                            ? c?.profile_picture
                            : GroupImage
                        }
                        name={
                          c?.group_id?.name
                            ? c?.group_id?.name
                            : c.name
                            ? c.name
                            : ""
                        }
                        // status={index === 2 ? "available" : "unavailable"}
                      />
                    </Conversation>
                  );
                })}
            </ConversationList>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <p style={{ fontSize: 20, color: "grey" }}>No Groups Available</p> */}
              <p style={{ fontSize: 20, color: "grey" }}>
                {isActiveGroups === "Invitation"
                  ? "No Invitations Available"
                  : "No Groups Available"}
              </p>
            </div>
          )}
        </Sidebar>

        {/* messages List */}

        {loading ? (
          <div className="loader">
            <img height={50} src={Loader} alt="loading" />
          </div>
        ) : (
          <ChatContainer style={chatContainerStyle} className="chat-container">
            {
              groupClicked && (

          
            <ConversationHeader className="cs-conversation-header">
              <ConversationHeader.Back onClick={handleBackClick} />
              {!groupClicked ? null : (
                <Avatar
                  src={activeAvatar ? activeAvatar : GroupImage}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsShowGroupDetail(true)}
                />
              )}
            

              {!groupClicked ? null : (
                <ConversationHeader.Content
                  onClick={() => {
                    setIsShowGroupDetail(true);
                  }}
                  className="activeName"
                  style={{ cursor: "pointer" }}
                  userName={activeName}
                />
       
              )}
     
              {isActiveGroups === "MyGroup" && groupClicked ? (
                <ConversationHeader.Actions>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 15,
                    }}
                  >
                    {groupData?.length === 0 && groupClicked ? null : (
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 50,
                          // background: "blue",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsAddUser(true)}
                      >
                        <AddUserIcon style={{ color: "blue", fontSize: 30 }} />
                      </div>
                    )}

                    {isAddUser && (
                      <InviteUserForGroup
                        addUserModalHandler={setIsAddUser}
                        isShowAddUserModal={isAddUser}
                        groupData={groupAllDetailsData?.group}
                      />
                    )}
                    {groupData?.length === 0 ? (
                      <div style={{ width: 40, height: 40 }}></div>
                    ) : (
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 50,
                          background: "blue",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsModalOpen(true)}
                      >
                        <AddPollIcon style={{ color: "white", fontSize: 30 }} />
                      </div>
                    )}
                  </div>
                </ConversationHeader.Actions>
              ) : null}
            </ConversationHeader>
    )
  }
            {/* message list start here */}
            {isActiveGroups !== "Invitation" && groupClicked && (
              <MessageList ref={msgListRef} className="messagesList">
                {/* PollComponent ////////////////////// */}
                {polldata?.length ? (
                  <>
                    {polldata.reverse().map((item: any, index: any) => {
                      // console.log('pollData item :>> ', item);
                      return (
                        <Message
                          model={{
                            direction: "incoming",
                            type: "custom",
                            position: "normal",
                          }}
                        >
                          <Message.CustomContent>
                            <PollComponent
                              data={polldata ? polldata : null}
                              item={item}
                              index={index}
                              isRefreshPollData={getPollData}
                              onToggleNotification={(item: any) =>
                                handleToggleNotification(item)
                              }
                            />
                          </Message.CustomContent>
                        </Message>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: 20, color: "grey" }}>
                      {groupData.length === 0 || !groupClicked ? "" : "No Poll Available"}
                    </p>
                  </div>
                )}
              </MessageList>
            )}
          </ChatContainer>
        )}

        {/* Right sidebar //////////////// */}

        {isShowGroupDetail && (
          <Sidebar
            position="right"
            style={sidebarRightStyle}
            id="side-bar-right"
            className="scrollbar-container cs-sidebar cs-sidebar--right right-SideBar ps"
          >
            <div style={{ backgroundColor: "#f1f1f1", width: "100%" }}>
              <div className="headerContainer">
                <div
                  style={{
                    width: "10%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsShowGroupDetail(false)}
                >
                  <CloseIcon style={{ color: "black", fontSize: 30 }} />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "Nunito",
                      fontWeight: "600",
                      lineHeight: 2,
                      marginBottom: 0,
                    }}
                  >
                    Group Info
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Nunito",
                      fontWeight: "500",
                      lineHeight: 1,
                    }}
                  >
                    Created{" "}
                    {moment(groupAllDetailsData?.group?.created_at).format(
                      "D MMM YYYY [at] h:mm A"
                    )}
                  </p>
                </div>
              </div>

              <div
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  backgroundColor: "#fff",
                }}
              >
                {isActiveGroups === "MyGroup" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingTop: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => showCreateGroup("Update")}
                  >
                    <EditIcon style={{ color: "#018BF5", fontSize: 30 }} />
                  </div>
                )}

                <div className="detail-list-group-avatar-Container">
                  <img
                    src={
                      groupAllDetailsData?.group?.profile_picture
                        ? groupAllDetailsData?.group?.profile_picture
                        : GroupImage
                    }
                    className="detail-list-group-avatar"
                    alt=""
                  />
                </div>
                <div className="groupNameContainer" style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "Nunito",
                      fontWeight: "600",
                      color: "#1f1f1f",
                      // textTransform: "capitalize",
                    }}
                  >
                    {groupAllDetailsData?.group?.name
                      ? groupAllDetailsData?.group?.name
                      : ""}
                  </p>
                </div>
                <div className="groupNameContainer">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "Nunito",
                      fontWeight: "400",
                      color: "grey",
                      // textTransform: "capitalize",
                    }}
                  >
                    {groupAllDetailsData?.group?.description
                      ? groupAllDetailsData?.group?.description
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // border: "1 solid black",
                    // backgroundColor: "grey",
                    borderRadius: 7,
                    paddingLeft: 10,
                    paddingRight: 15,
                    boxShadow: "1px 2px 5px grey",
                    marginTop: 15,
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "Nunito",
                      fontWeight: "600",
                      margin: 0,
                      marginBottom: "0rem",
                    }}
                  >
                    Participants
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "Nunito",
                      fontWeight: "600",
                      color: "blue",
                      margin: 0,
                      marginBottom: "0rem",
                    }}
                  >
                    {groupAllDetailsData?.user_count
                      ? groupAllDetailsData?.user_count
                      : 0}
                  </p>
                </div>

                {/* Followers User in group //////// */}
                {groupAllDetailsData?.user?.users.map(
                  (ele: any, index: any) => {
                    console.log("ele?.avatar", ele);
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: 7,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          marginTop: 8,
                          // cursor: "pointer",
                          boxShadow: "1px 2px 5px grey",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <div className="userImageContainer">
                            <img
                              // src={
                              //   ele?.avatar ===
                              //   ("null" || "" || null || undefined)
                              //     ? UserImage
                              //     : ele?.avatar
                              // }
                              src={ele?.avatar ? ele?.avatar : UserImage}
                              alt=""
                              className="userImage"
                            />
                          </div>
                          <div
                          // style={{ backgroundColor: "red" }}
                          >
                            <p
                              style={{
                                fontSize: 20,
                                fontFamily: "Nunito",
                                fontWeight: "600",
                                marginBottom: "0rem",
                              }}
                            >
                              {ele?.name ? ele?.name : ""}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: 65,
                          }}
                        >
                          {isActiveGroups === "MyGroup" && (
                            <div
                              className="exitUser-Container"
                              data-id={index}
                              style={{ borderRadius: 6 }}
                              onClick={() => {
                                // setIsShowRemoveUserModal(true);
                                // setUserRemoveData(ele);
                                setEditUserData(ele);
                                setIsShowEditUserModal(true);
                              }}
                            >
                              {/* <PersonIcon
                              style={{ color: "green", fontSize: 35 }}
                            /> */}

                              <EditIcon
                                style={{ color: "#018BF5", fontSize: 20 }}
                              />
                              <p
                                style={{
                                  fontSize: 10,
                                  color: "#018BF5",
                                  marginBottom: "0rem",
                                }}
                              >
                                Edit
                              </p>
                            </div>
                          )}
                          {isActiveGroups === "MyGroup" && (
                            <div
                              className="deleteUser-Container"
                              data-id={index}
                              style={{ borderRadius: 6 }}
                              onClick={() => {
                                setIsShowRemoveUserModal(true);
                                setUserRemoveData(ele);
                              }}
                            >
                              {/* <PersonIcon
                              style={{ color: "green", fontSize: 35 }}
                            /> */}

                              <DeleteIcon
                                style={{ color: "#F24E1E", fontSize: 20 }}
                              />
                              <p
                                style={{
                                  fontSize: 10,
                                  color: "#F24E1E",
                                  marginBottom: "0rem",
                                }}
                              >
                                Delete
                              </p>
                            </div>
                          )}
                        </div>
                        {isShowRemoveUserModal && (
                          <RemoveUserByAdminModal
                            setIsShowRemoveUserModalHandler={
                              setIsShowRemoveUserModal
                            }
                            isShowRemoveUserModal={isShowRemoveUserModal}
                            data={removeUserData}
                            isRefreshGroupDetailData={groupAllDetailDataHandler}
                            group_uuid={groupAllDetailsData?.group?.uuid}
                          />
                        )}
                        {isShowEditUserModal && (
                          <EditUserByAdminModal
                            setIsShowEditUserModalHandler={
                              setIsShowEditUserModal
                            }
                            isShowEditUserModal={isShowEditUserModal}
                            data={editUserData}
                            isRefreshGroupDetailData={groupAllDetailDataHandler}
                            group_uuid={groupAllDetailsData?.group?.uuid}
                            onAssignAdminClick={handleAssignUserAsAdmin}
                          />
                        )}
                      </div>
                    );
                  }
                )}

                {/* Admin User in group //////// */}
                {groupAllDetailsData?.user?.group_admin.map((ele: any) => {
                  return <UserListGroupDetail item={ele} isAdmin={true} />;
                })}

                {isActiveGroups === "MyGroup" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: 15,
                      cursor: "pointer",
                      gap: 12,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      borderRadius: 10,
                    }}
                    className="deleteContainer"
                    onClick={() => setIsShowDeleteGroupModal(true)}
                  >
                    <DeleteIcon style={{ color: "#ea0038", fontSize: 35 }} />
                    <p
                      style={{
                        color: "#ea0038",
                        fontSize: 18,
                        fontFamily: "Nunito",
                        fontWeight: "600",
                        margin: 0,
                      }}
                    >
                      Delete Group
                    </p>
                  </div>
                )}

                {isActiveGroups === "FollowingGroup" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: 15,
                      cursor: "pointer",
                      gap: 12,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      borderRadius: 10,
                    }}
                    className="deleteContainer"
                    onClick={() => setIsShowLeaveGroupModal(true)}
                  >
                    <GroupRemoveIcon
                      style={{ color: "#ea0038", fontSize: 35 }}
                    />
                    <p
                      style={{
                        color: "#ea0038",
                        fontSize: 18,
                        fontFamily: "Nunito",
                        fontWeight: "600",
                        margin: 0,
                      }}
                    >
                      Leave Group
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Sidebar>
        )}
      </MainContainer>

      {isShowDeleteGroupModal && (
        <GroupDeleteModal
          data={groupAllDetailsData}
          isShowDeleteGroupModal={isShowDeleteGroupModal}
          setIsShowDeleteGroupModalHandler={setIsShowDeleteGroupModal}
          isRefreshMyGroupData={getMyGroupData}
          closeGroupDetailModal={setIsShowGroupDetail}
          // getMyGroupData={getMyGroupData}
        />
      )}
      {isShowLeaveGroupModal && (
        <GroupLeaveModal
          data={groupAllDetailsData}
          isShowLeaveGroupModal={isShowLeaveGroupModal}
          setIsShowLeaveGroupModalHandler={setIsShowLeaveGroupModal}
          isRefreshMyGroupData={getFollowingGroupsData}
          closeGroupDetailModal={setIsShowGroupDetail}
          onLeaveGroupHandler={() => handleLeaveGroup()}
        />
      )}
      {isModalOpen && (
        <CreatePoll
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          groupID={myGroup_uuid}
          refresh={getPollData}
          refreshid={refreshid}
        />
      )}
      {isActiveGroups === "Invitation" || inviteAcceptAndRejectModal ? (
        <Modal
          open={inviteAcceptAndRejectModal}
          onCancel={() => {
            setInviteAcceptAndRejectModal(false);
            handleBackClick();
          }}
          maskClosable={false}
          closable={true}
          footer={false}
          // centered={true}
          // style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70%',width:300}}
          // className="invitationModal"
          // width={500}
          // bodyStyle={{
          //   width:'100%',
          //   // flex:1,
          //   // backgroundColor:'white'
          //   paddingLeft:100,
          //   paddingRight:100
          // }}
          centered={true}     
        >
          <div style={{width:'100%'}} className="invitationModalDiv">
            <p
              style={{
                fontSize: 30,
                fontFamily: "Nunito",
                fontWeight: "600",
                color: "grey",
                textAlign: "center",
              }}
            >
              Invitation
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // marginTop: 20,
                gap: 20,
                // marginBottom: 20,
                
              }}
            >
              <Button
                onClick={() => inviteAcceptHandler("Accept")}
                style={{
                  backgroundColor: "blue",
                  fontFamily: "Nunito",
                  fontSize: 20,
                  color: "white",
                }}
              >
                Accept
              </Button>
              <Button
                style={{
                  backgroundColor: "red",
                  fontFamily: "Nunito",
                  fontSize: 20,
                  color: "white",
                }}
                onClick={() => inviteAcceptHandler("Reject")}
              >
                Reject
              </Button>
            </div>
          </div>
        </Modal>

      ) : (
        "modal not opening"
      )}
      <ToastContainer />
    </div>
  );
};
export default Chat;
