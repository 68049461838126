import React, { useState, useContext, useRef } from "react";
import { Button, Modal, Input } from "antd";
import HashImage from "../../assets/user-image.png";
import className from "./Group.css";
import axios from "axios";
import { GlobalContext } from "../../Context/context";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupImage from "../../assets/group.jpeg";


const CustomCloseButton = ({ closeToast }) => (
  <button onClick={closeToast}>
    a
  </button>
);
const CreateGroups = ({
  isModalOpen,
  setIsCreateGroup,
  groupType,
  data,
  onCreateGroupSuccess,
  onUpdateGroupSuccess,
}) => {
  console.log("data (CreateGroups): ", data);
  console.log("groupType", groupType);
  let { state, dispatch } = useContext(GlobalContext);
  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [groupName, setIsGroupName] = useState(
    groupType === "Update" ? data?.name : ""
  );
  const [groupDescription, setGroupDescription] = useState(
    groupType === "Update" ? data?.description : ""
  );
  const [src, setSrc] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [smaeNameError, setSameNameError] = useState(false);
  const toastId = useRef(null)
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,

  });

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const createGroupHandler = async () => {
    toast.dismiss();
    const validationErrors = {};
    setIsLoading(true);
    if (!groupName) {
      validationErrors.name = "Name is required";
      setIsLoading(false)
    } else if (groupName?.length < 3) {
      validationErrors.name = "Name must be at least 3 characters long.";
      setIsLoading(false)
    }

    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false)
      return setErrors(validationErrors);
    } else {
      setErrors({});

      if (groupType === "Create") {
        const data = {
          name: groupName ? groupName : "",
          description: groupDescription ? groupDescription : null,
          profile_picture: src ? src : null,
        };
        console.log("data", data);
        const createGroup_URL = `${state?.baseUrl}group/store`;
        try {
          const response = await axios.post(createGroup_URL, data, {
            headers: headers,
          });
          console.log("Create Group response", response?.data);
          if (response?.data?.status === 201) {
         
            toast.success(
              response?.data?.message
                ? response?.data?.message
                : "Group created successfully",{
                  toastId:'created'
                });


          }
          onCreateGroupSuccess();
          setIsCreateGroup(false);
          setIsGroupName("");
          setGroupDescription("");
          setSrc(null);
          setIsLoading(false)
        } catch (error) {
          console.log("Create Group Error!", error);
          if (error?.response?.data?.status == 400) {
            // Toast.fire({
            //   icon: "success",
            //   title: "The name has already been taken.",
            // });
            toast.error("The name has already been taken.");
            setIsLoading(false)
            setSameNameError(true)

          }


        }
      } else {
        const updateData = {
          name: groupName ? groupName : data?.name ? data?.name : "",
          description: groupDescription
            ? groupDescription
            : data?.description
              ? data?.description
              : null,
          profile_picture: src ? src : null,
          privacy: data?.privacy ? data?.privacy : "",
          status: data?.status ? data?.status : "",
        };
        const updateGroup_URL = `${state?.baseUrl}group/${data?.uuid}/update`;
        try {
          const response = await axios.post(updateGroup_URL, updateData, {
            headers: headers,
          });
          console.log("update Group response", response?.data);
          if (response?.data?.status === 200) {
            setIsCreateGroup(false);
            setIsGroupName("");
            setGroupDescription("");
            setSrc(null);
            toast.success(
              response?.data?.message
                ? response?.data?.message
                : "The group information has been successfully updated."
            );

            onUpdateGroupSuccess();

            // function refreshPage() {
            //   window.location.reload();
            // }

            // setTimeout(refreshPage, 7000);
            setIsLoading(false)

          }
        } catch (error) {
          console.log("Update Group Error!", error);
          if (error?.response?.data?.status === 400) {
            // Toast.fire({
            //   icon: "success",
            //   title: "The name has already been taken.",
            // });
            toast.error("The name has already been taken.");
            setSameNameError(true)
            setIsLoading(false)

          }

        }
      }
    }
  };

  return (
    <div>
      <Modal
        title=""
        open={isModalOpen}
        // onOk={}
        onCancel={() => setIsCreateGroup(false)}
        // okText={"Create"}
        closeIcon={true}
        maskClosable={false}
        footer={null}
      >
        <div>
          <div className="detail-list-group-avatar-Container">
            <img
              src={
                src
                  ? src
                  : groupType === "Update" && data?.profile_picture
                    ? data?.profile_picture
                    : GroupImage
              }
              className="detail-list-group-avatar"
              alt=""
            />
          </div>
          <div style={{ display: "", border: "1 solid red" }}>
            <input type="file" accept="image/*" onChange={onSelectFile} />
          </div>
          <div className="inputContainer">
            <Input
              className="questionFiels"
              onChange={(e) => setIsGroupName(e.target.value)}
              placeholder="Enter Group Name "
              type="name"
              value={groupName}
            />
            {errors && (
              <p
                style={{
                  fontSize: 12,
                  fontFamily: "Nunito",
                  color: "red",
                  fontWeight: "500",
                  paddingLeft: 12,
                  paddingTop: 5,
                }}
              >
                {errors?.name}
              </p>
            )}
          </div>
          <div className="inputContainer">
            <Input
              className="questionFiels"
              // showCount
              // maxLength={100}
              onChange={(e) => {
                setGroupDescription(e.target.value)
                setSameNameError(false)
              }}
              placeholder="Enter Description"
              value={groupDescription}

            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className=" btn btn-primary mt-3 mb-3"
              onClick={createGroupHandler}
              // onClick={onCreateGroupClick}
              disabled={isLoading}
            >
              {groupType} Group
            </button>
          </div>
        </div>
      </Modal>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
      <ToastContainer />
    </div>
  );
};

export default CreateGroups;
