import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Loader from "./assets/Rolling-1s-200px.gif";
import App from "./App";
import { GlobalContext } from "./Context/context";
import {
  Routes,
  Route,
  Link,
  Navigate,
  useMatch,
  useLocation,
} from "react-router-dom";
import ChatScreen from "./components/chatscreen";
import OneSignal from "react-onesignal";
// import UsersState from './Context/reducer';
import AccountSetting from "./accountSettingPage/AccountSetting";
import { BrowserRouter } from "react-router-dom";
import ForgotPsword from "./components/auth/forgetPassword/ForgotPsword";
import LoginPage from "./components/auth/login/Login";
import SignupPage from "./components/auth/signup/Signup";
import CreatePassword from "./components/auth/forgetPassword/CreatePassword";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, toast } from "react-toastify";
// import Crypto from 'crypto'
// import 'react-toastify/dist/ReactToastify.css';
const FrontPage = () => {
  // navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(function(registration) {
  //   console.log('ServiceWorker registration successful with scope: ', registration.scope);
  //        }, function(err) {
  //   console.log('ServiceWorker registration failed: ', err);
  //   });

  // const navigate = useNavigate();
  let { state, dispatch } = useContext(GlobalContext);

  // const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [initialized, setInitialized] = useState(false);

  let user_Token = localStorage.getItem("token");

  const location = useLocation(); // Get the current location
  const headers = {
    headers: {
      Authorization: `Bearer ${user_Token}`,
    },
  };

  useEffect(() => {
    OneSignal.init({ appId: "81b65e64-1e1c-4691-8c11-ca6fdd560d6b" });
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      axios
        .get(`${state.baseUrl}user/profile`, headers)
        .then(function (response) {
          if (response?.data?.status === 200) {
            dispatch({
              type: "USER_LOGIN",
              payload: response.data.user,
            });
          }
          // console.log("re", response);
        })
        .catch(function (error) {
          dispatch({
            type: "USER_LOGOUT",
          });
          // console.error(error);
        });
    };
    getUserData();
  }, []);

  console.log("App.js user_Token", user_Token);
  return (
    <>
      <Routes>
        {/* GJELFVJEJ /////////////// */}
        <Route
          path="/"
          element={
            <ProtectedRoute user_Token={user_Token}>
              <App />
            </ProtectedRoute>
          }
        />

        <Route
          path="/login"
          element={
            user_Token ? <Navigate to="/" replace={true} /> : <LoginPage />
          }
        />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forget-password" element={<ForgotPsword />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="*" element={<Navigate to="/login" replace={true} />} />
        {/* <Route path="/" element={
          <ProtectedRoute user_Token={user_Token}>
            <App />
          </ProtectedRoute>} /> */}
        <Route path="/m/:id/" element={<ChatScreen />} />

        <Route path="/account-setting" element={<AccountSetting />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
        <Route path="/m/:uuid" element={<ChatScreen />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default FrontPage;
