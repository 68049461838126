import React, { useState, useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import classNameName from "./Login.css";
import "./Signup.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import InfoIcon from "@mui/icons-material/Info";
import { Formik } from "formik";
import image from "../../../assets/loginpage.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import NameIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@material-ui/core";

import { GlobalContext } from "../../../Context/context.js";
import Loader from "../../../assets/loader.gif";
import loginImage from "../../../assets/login-image.png";
import topShape from "../../../assets/top-corner-shape.png";

// CommonJS
const SignupPage = () => {
  // const user = useContext(UserContext);

  const navigate = useNavigate();
  let { state, dispatch } = useContext(GlobalContext);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });
  function includesNumber(str) {
    return /\d/.test(str);
  }
  //Login Function
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {};
    setIsLoading(true);

    if (!name) {
      validationErrors.name = "Name is required";
      setIsLoading(false);
    } else if (name?.length < 3) {
      validationErrors.name = "Name must be at least 3 characters long";
      setIsLoading(false);
    }
    if (includesNumber(name)) {
      validationErrors.name = "Numbers are not allowed in name.";
      setIsLoading(false);
    }

    // if (!userName) {
    //     validationErrors.username = "UserName is required";
    // } else if (userName?.length < 3) {
    //     validationErrors.username = "UserName must be at least 3 characters long";
    // } else if (!/^[^\s]+$/.test(userName)) {
    //     validationErrors.username = "Username cannot contain spaces. Please enter a valid username.";
    // };

    if (phone?.length > 1 && phone?.length < 6) {
      validationErrors.phone_number = "Phone number must be atleast 6 digits";
      setIsLoading(false);
    } else if (phone?.length > 15) {
      validationErrors.phone_number =
        "The number should have at most 15 digits";
      setIsLoading(false);
    }

    if (!email) {
      validationErrors.email = "Email is required";
      setIsLoading(false);
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Invalid email format";
      setIsLoading(false);
    }

    if (!password) {
      validationErrors.password = "Password is required";
    } else if (password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    }

    if (!confirmPassword) {
      validationErrors.confirm_password = "Password is required";
      setIsLoading(false);
    } else if (password !== confirmPassword) {
      validationErrors.confirm_password = "Passwords does not match";
      setIsLoading(false);
    }

    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return setErrors(validationErrors);
    } else {
      setErrors({});
      // submit the form
      // console.log('Email:', email);
      // console.log('Password:', password);
      const data = {
        name: name,
        email: email,
        password: password,
        phone: phone ? phone : null,
      };
      console.log("data", data);
      try {
        const signup_URL = `${state.baseUrl}register`;

        const response = await axios.post(signup_URL, data);
        console.log("Signup response ==>>> ", response?.data);

        // localStorage.setItem("token", user_token);
        // localStorage.setItem("user_ID", user_id);
        if (response?.data?.status === 201) {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(response?.data?.user?.id);
          }
          dispatch({
            type: "USER_LOGIN",
            payload: response?.data?.user,
          });
          localStorage.setItem("token", response?.data?.user?.token);
          // Toast.fire({
          //   icon: "success",
          //   title: response?.data?.message
          //     ? response?.data?.message
          //     : "User registered successfully.",
          // });
          toast.success(
            response?.data?.message
              ? response?.data?.message
              : "User registered successfully"
          );

          navigate("/");
          setIsLoading(false);
        }
      } catch (error) {
        console.log("Signup error", error);

        if (error?.response?.data?.status === 400) {
          console.log("error occured status 400");

          if (error?.response?.data?.message?.hasOwnProperty("phone")) {
            toast.error(error?.response?.data?.message?.phone[0]);
          }

          if (error?.response?.data?.message?.hasOwnProperty("email")) {
            toast.error(error?.response?.data?.message?.email[0]);
          }
        }

        // if (error && error?.response?.data?.status == 400) {
        //   // Toast.fire({
        //   //   icon: "error",
        //   //   title: error?.response?.data?.message?.email[0]
        //   //     ? error?.response?.data?.message?.email[0]
        //   //     : "Please try again.",
        //   // });
        //   if (error?.response?.message?.phone) {
        //     showErrorToast("phone", error);
        //   } else if (error?.response?.message?.email) {
        //     showErrorToast("email");
        //   }
        // }
        dispatch({
          type: "USER_LOGOUT",
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (includesNumber(name)) {
      setNameError("Numbers are not allowed in name property");
    } else {
      setNameError("");
    }
    // console.log(includesNumber(name));
  }, [name]);

  return (
    <>
      <div className="main_container">
        {/*  ============ Left column ============ */}
        <div className="column">
          <div className="top-left-corner">
            <img src={topShape} className="top-corner-shape" />
          </div>
          <img src={loginImage} className="login-image" />
        </div>

        {/*  ============ Right column ============ */}
        <div className="column">
          <div className="login-form-container">
            <p className="login-heading">Sign Up</p>

            <form id="loginForm" onSubmit={handleSubmit}>
              <TextField
                className="formfields"
                // label="Name"
                placeholder="Name"
                type="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                error={!!errors.name}
                helperText={errors.name}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NameIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginTop: 30 }}></div>

              <TextField
                className="formfields"
                // label="Phone (Optional)"
                placeholder="Phone (Optional)"
                type="number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                error={!!errors.phone_number}
                helperText={errors.phone_number}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginTop: 30 }}></div>

              <TextField
                className="formfields"
                // label="Email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginTop: 30 }}></div>

              <TextField
                className="formfields"
                // label="Password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={!!errors.password}
                helperText={errors.password}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginTop: 30 }}></div>

              <TextField
                className="formfields"
                // label="Confirm Password"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                error={!!errors.confirm_password}
                helperText={errors.confirm_password}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </form>

            <div className="login-btn-container">
              <button
                className="login-btn"
                form="loginForm"
                disabled={isLoading}
              >
                Register
              </button>
            </div>

            <div className="signup-lnk-container">
              <p className="signup-txt">Already Have An Account?</p>
              <Link className="signup-link_text" to="/Login">
                Login
              </Link>
            </div>
          </div>

          <div className="shape-container"></div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default SignupPage;
