import React, { useContext, useState } from "react";
import { Modal } from "antd";
import axios from "axios";
import { GlobalContext } from "../Context/context";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RemoveUserByAdminModal = ({
  setIsShowRemoveUserModalHandler,
  isShowRemoveUserModal,
  data,
  isRefreshGroupDetailData,
  group_uuid,
}) => {
  let { state, dispatch } = useContext(GlobalContext);
  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));
  const { pivot, name } = data ? data : {};

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });

  const removeUserFromGroupByAdmin = async () => {
    try {
      const response = await axios.post(
        `${state.baseUrl}delete/user/${pivot?.user_id}/group/${pivot?.group_id}`,
        {},
        { headers: headers }
      );
      console.log("User Remove from Group response :>> ", response?.data);
      if (response?.data?.status === 200) {
        setIsShowRemoveUserModalHandler(false);
        isRefreshGroupDetailData(group_uuid);
        // Toast.fire({
        //     icon: "success",
        //     title: response?.data?.message ? response?.data?.message : "The group has been deleted successfully.",
        // });
        toast.success(
          response?.data?.message
            ? response?.data?.message
            : "The group has been deleted successfully."
        );
      }
    } catch (error) {
      console.log("User Remove from Group Error :>> ", error);
      //   Toast.fire({
      //     icon: "error",
      //     title: error?.response?.data?.message
      //       ? error?.response?.data?.message
      //       : "",
      //   });
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : ""
      );
      setIsShowRemoveUserModalHandler(false);
    }
  };

  return (
    <Modal
      open={isShowRemoveUserModal}
      onCancel={() => setIsShowRemoveUserModalHandler(false)}
      maskClosable={false}
      closable={true}
      footer={false}
      // centered={true}
    >
      <div style={{ backgroundColor: "#ffffff", marginTop: 20 }}>
        <p
          style={{
            fontSize: 25,
            color: "#3b4a54",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
        >
          Remove follower {name ? name : ""}!
        </p>
        <p
          style={{
            fontSize: 18,
            color: "#667781",
            fontFamily: "Nunito",
            fontWeight: "500",
            marginTop: 10,
          }}
        >
          Are you sure you want to remove this user?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 20,
            marginTop: 60,
          }}
        >
          <button
            className="btn"
            onClick={() => setIsShowRemoveUserModalHandler(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={removeUserFromGroupByAdmin}
          >
            Remove User
          </button>
        </div>
      </div>

      <ToastContainer />
    </Modal>
  );
};

export default RemoveUserByAdminModal;
