import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import className from "../login/Login.css";
import { Link } from "react-router-dom";
// import UserContext from "../../Context/context.js";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import image from "../../../assets/loginpage.png";
import { InputAdornment } from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "react-spinner-material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './ForgotPassword.css'
import { GlobalContext } from "../../../Context/context.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    'anger': '#018bf5',
  },
});
const ForgotPsword = () => {
  let { state, dispatch } = useContext(GlobalContext);
  // const hellow = useContext(UserContext);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsloading] = useState(false)
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsloading(true)
    const validationErrors = {};

    if (!email) {
      validationErrors.email = "Email is required";
      setIsloading(false)
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Invalid email format";
      setIsloading(false)
    }

    if (Object.keys(validationErrors).length > 0) {
      setIsloading(false)
      setErrors(validationErrors);
      return
    } else {
      // submit the form
      setErrors({});
      try {
        setLoader(true);
        const forget_URL = `${state.baseUrl}forgot/email`;

        const response = await axios.post(forget_URL, { email });
        console.log(
          "Forget Password response=====================",
          response?.data
        );
        if (response?.data?.status === 200) {
          setLoader(false);
          // Toast.fire({
          //   icon: "success",
          //   title: `${response?.data?.message ? response?.data?.message : ""}`,
          // });
          setIsloading(false)
          toast.success(response?.data?.message ? response?.data?.message : "");
          navigate("/create-password", { state: { userEmail: email } });
          setEmail("");

        }
      } catch (error) {
        console.log("Forget Error", error);

        if (error?.response?.data?.status === 401) {
          // Toast.fire({
          //   icon: "error",
          //   title: `${error?.response?.data?.message}`,
          // });

          toast.error(error?.response?.data?.message);

          setLoader(false);
          setIsloading(false)
        }
      }
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="HA_main_div_of_from">
        {/* <img className="HA_main_div_scnd mb-none" src={image} style={{width:'50%',height:'50%'}}/> */}
        <div className="HA_form_main_div" style={{ width: '50%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <p className="HA_from_heading_text1">Reset Password</p>

          <form id="resetForm" onSubmit={handleSubmit}>
            <TextField
              className="formfields"
              label="Email"
              placeholder="User Email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={!!errors.email}
              helperText={errors.email}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%', alignSelf: 'center' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Link className="HA_from_link_text" to="/login">
              Login
            </Link>
          </form>
          {/* } */}
          {/* <div className="HA_from_link_MAIn" style={{textAlign:'center',alignSelf:'center',paddingRight:180}> */}

          {/* </div> */}
          <div className="HA_from_main_div_btn">
            {/* <Button
              className="HA_from_login_btn_div"
              variant="contained"
              color="primary"

              type="submit"
              form="resetForm"
              disabled={isLoading}
            >
              {loader ? (
                <Spinner
                  size={12}
                  spinnerColor={"#333333"}
                  spinnerWidth={2}
                  visible={loader}
                />
              ) : (
                "Send"
              )}
            </Button> */}
                  <div className="login-btn-container">
              <button className="login-btn" form="resetForm" disabled={isLoading}>
              {/* {loader ? (
                <Spinner
                  size={5}
                  spinnerColor="#fff"
                  spinnerWidth={2}
                  visible={loader}

                />
              ) : ( */}
                Send
              {/* )} */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ForgotPsword;
